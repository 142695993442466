import React from "react"
import { Illustration } from "types/components"
import { mergeClasses } from "utils"

export const Pattern11Illustration: Illustration = ({
	className = "",
	...props
}) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 66 66"
			className={mergeClasses({ classes: ["w-20", className] })}
			{...props}
		>
			<path
				fill="url(#paint0_linear_5622_37778)"
				fillRule="evenodd"
				d="M35.062 65.997C52.186 65.777 66 51.827 66 34.65H35.062v31.347z"
				clipRule="evenodd"
			></path>
			<path
				fill="url(#paint1_linear_5622_37778)"
				fillRule="evenodd"
				d="M0 31.584h31.762v31.113a31.47 31.47 0 01-.412.003C14.114 62.7.126 48.79 0 31.584z"
				clipRule="evenodd"
			></path>
			<path
				fill="url(#paint2_linear_5622_37778)"
				fillRule="evenodd"
				d="M31.762 31.556H62.7l.001-.206C62.7 14.173 48.886.223 31.762.002a31.996 31.996 0 00-.412-.003C14.035 0 0 14.035 0 31.35v.207h31.762z"
				clipRule="evenodd"
			></path>
			<defs>
				<linearGradient
					id="paint0_linear_5622_37778"
					x1="34.65"
					x2="56.512"
					y1="34.65"
					y2="57.75"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#1753E2"></stop>
					<stop offset="1" stopColor="#DEE9FD"></stop>
				</linearGradient>
				<linearGradient
					id="paint1_linear_5622_37778"
					x1="31.35"
					x2="8.001"
					y1="31.584"
					y2="53.458"
					gradientUnits="userSpaceOnUse"
				>
					<stop offset="0.033" stopColor="#7BC598"></stop>
					<stop offset="1" stopColor="#9CF1BE"></stop>
				</linearGradient>
				<linearGradient
					id="paint2_linear_5622_37778"
					x1="38.362"
					x2="27.637"
					y1="31.35"
					y2="-0.001"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#F39244"></stop>
					<stop offset="1" stopColor="#F8CCA9"></stop>
				</linearGradient>
			</defs>
		</svg>
	)
}
