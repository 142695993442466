import React from "react"
import { Illustration } from "types/components"
import { mergeClasses } from "utils"

export const MicroscopeResearchIllustration: Illustration = ({
	className = "",
	...props
}) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 425 396"
			className={mergeClasses({ classes: ["w-40", className] })}
			{...props}
		>
			<g fill="#A6BFF7" opacity="0.5">
				<path d="M161.38 204.239c-9.447 0-16.846-17.707-16.846-40.318 0-22.61 7.399-40.317 16.846-40.317s16.857 17.707 16.857 40.317c0 22.611-7.399 40.318-16.857 40.318zm0-78.173c-7.813 0-14.405 17.348-14.405 37.866 0 20.519 6.538 37.866 14.405 37.866 7.867 0 14.416-17.336 14.416-37.866 0-20.529-6.603-37.866-14.416-37.866z"></path>
				<path d="M184.695 188.919c-8.39 0-19.962-3.585-31.741-10.385-19.614-11.3-31.209-26.566-26.479-34.749 4.729-8.184 23.754-5.754 43.336 5.546 19.614 11.311 31.197 26.566 26.479 34.761-1.886 3.258-6.037 4.827-11.595 4.827zm-46.855-47.553c-4.533 0-7.868 1.166-9.295 3.639-1.798 3.106-.229 8.184 4.293 13.926 4.817 6.135 12.379 12.335 21.292 17.435 17.773 10.264 36.09 13.217 40.002 6.451 3.912-6.767-7.813-21.151-25.585-31.405-11.257-6.483-22.774-10.046-30.664-10.046h-.043z"></path>
				<path d="M138.059 188.919c-5.546 0-9.698-1.569-11.583-4.827-2.354-4.087-.763-10.004 4.489-16.672 5.002-6.364 12.815-12.793 21.99-18.089 19.614-11.3 38.607-13.73 43.336-5.546 4.729 8.183-6.909 23.449-26.479 34.749-11.779 6.8-23.362 10.385-31.753 10.385zm16.116-37.463c-8.913 5.143-16.475 11.343-21.292 17.435-4.522 5.742-6.091 10.82-4.293 13.926 3.912 6.766 22.229 3.813 40.002-6.451 17.772-10.265 29.486-24.638 25.585-31.404-3.901-6.767-22.229-3.814-40.002 6.45v.044z"></path>
				<path d="M161.379 172.029a8.098 8.098 0 01-7.941-9.676 8.095 8.095 0 0116.037 1.58 8.109 8.109 0 01-8.096 8.096zm0-13.741a5.648 5.648 0 00-5.207 3.492 5.644 5.644 0 105.207-3.492z"></path>
			</g>
			<path
				fill="#B5BBC4"
				d="M273.667 382.651H166.455l25.836-35.85h74.915l6.461 35.85z"
			></path>
			<path
				fill="#DEE9FD"
				stroke="#5F85EB"
				d="M113.233 291.063v-.001a117.348 117.348 0 01-2.033-4.674h23.077c.899 1.744 1.842 3.462 2.886 5.143a95.109 95.109 0 0077.053 46.09 95.118 95.118 0 0093.937-127.435 95.116 95.116 0 00-65.968-59.753l16.714-16.725a116.47 116.47 0 0140.893 25.893l.022.033.038.037a116.088 116.088 0 0119.813 140.669 116.093 116.093 0 01-175.919 31.022 116.105 116.105 0 01-30.513-40.299z"
			></path>
			<path
				fill="#3C67F0"
				d="M193.85 291.239H70.489a4.522 4.522 0 00-4.522 4.522v10.973a4.522 4.522 0 004.522 4.522H193.85a4.522 4.522 0 004.522-4.522v-10.973a4.521 4.521 0 00-4.522-4.522z"
			></path>
			<path
				fill="#5094CC"
				d="M183.51 289.027v2.179H82.76v-2.179a3.173 3.173 0 013.17-3.171h94.409a3.173 3.173 0 013.171 3.171z"
			></path>
			<path
				fill="#C1C7D0"
				d="M304.212 382.237H166.434v9.044h137.778v-9.044z"
			></path>
			<path
				fill="#CCDBFB"
				d="M176.366 232.839l16.759-25.13-11.658-7.775-16.759 25.13 11.658 7.775z"
			></path>
			<path
				fill="#5F85EB"
				d="M184.644 230.049l12.926-19.382-20.551-13.706-12.927 19.382 20.552 13.706z"
			></path>
			<path
				fill="#DEE9FD"
				stroke="#A6BFF7"
				d="M199.507 223.91l-35.44-23.635 96.991-145.43 35.439 23.634-96.99 145.431z"
			></path>
			<path
				fill="#3C67F0"
				d="M302.687 79.51l5.622-8.431a2.081 2.081 0 00-.576-2.887l-41.529-27.696a2.082 2.082 0 00-2.887.577l-5.623 8.43a2.082 2.082 0 00.577 2.887L299.8 80.086c.957.638 2.249.38 2.887-.576z"
			></path>
			<path
				fill="#F0F5F7"
				stroke="#A6BFF7"
				d="M298.039 61.132l-21.56-14.379 19.469-29.193 21.56 14.379-19.469 29.193z"
			></path>
			<path
				fill="#3C67F0"
				d="M319.822 33.23l3.441-5.16a2.975 2.975 0 00-.825-4.125L301.424 9.931a2.975 2.975 0 00-4.125.824l-3.44 5.159a2.974 2.974 0 00.824 4.125l21.014 14.014a2.974 2.974 0 004.125-.824z"
			></path>
			<path
				fill="#F0F5F7"
				stroke="#5F85EB"
				d="M241.484 79.034h0l4.232-6.345a1.778 1.778 0 012.465-.493l5.268 3.514-6.204 9.303-5.25-3.502h0l-.004-.002a1.774 1.774 0 01-.773-1.83c.045-.23.136-.45.266-.645zM228.166 96.138l4.195-6.33a1.766 1.766 0 012.45-.498l5.254 3.482-6.148 9.277-5.254-3.481a1.766 1.766 0 01-.497-2.45zM218.389 113.671h0l4.232-6.346a1.777 1.777 0 012.465-.493l5.232 3.489-6.204 9.304-5.264-3.511a1.78 1.78 0 01-.461-2.443z"
			></path>
			<path
				fill="#3C67F0"
				d="M199.544 228.899l1.276-1.913a1.352 1.352 0 00-.374-1.874l-37.885-25.266a1.351 1.351 0 00-1.874.375l-1.276 1.912a1.351 1.351 0 00.375 1.874l37.884 25.266c.621.414 1.46.247 1.874-.374zM255.048 168.204c12.933 0 23.417-10.484 23.417-23.416 0-12.933-10.484-23.417-23.417-23.417-12.933 0-23.417 10.484-23.417 23.417 0 12.932 10.484 23.416 23.417 23.416z"
			></path>
			<path
				fill="#F0F5F7"
				d="M255.047 158.833c7.758 0 14.046-6.289 14.046-14.046s-6.288-14.046-14.046-14.046c-7.757 0-14.046 6.289-14.046 14.046s6.289 14.046 14.046 14.046z"
			></path>
			<path
				fill="#03254E"
				d="M238.919 356.609c1.752-7.378-2.808-14.779-10.185-16.531-7.378-1.752-14.779 2.808-16.531 10.186-1.752 7.377 2.808 14.778 10.186 16.53 7.377 1.752 14.778-2.808 16.53-10.185z"
			></path>
			<path
				fill="#F0F5F7"
				d="M233.576 355.332a8.237 8.237 0 00-6.116-9.915 8.237 8.237 0 10-3.799 16.031 8.237 8.237 0 009.915-6.116z"
			></path>
			<path
				fill="#F8FAFB"
				d="M279.096 364.541a12.053 12.053 0 018.755 3.397 12.047 12.047 0 01-4.033 19.884c-1.504.584-3.11.86-4.722.811h-97.1v-24.092h97.1z"
			></path>
			<path
				fill="#CED7E0"
				d="M285.536 381.942h-103.54v1.003h103.54v-1.003zM287.584 378.03H181.996v1.003h105.588v-1.003zM287.584 374.13H181.996v1.002h105.588v-1.002zM285.547 370.228H181.996v1.003h103.551v-1.003z"
			></path>
			<path
				fill="#DADFE8"
				stroke="#595959"
				strokeWidth="0.11"
				d="M279.094 391.063h-99.333v-4.859h99.333a9.62 9.62 0 009.622-9.623 9.62 9.62 0 00-9.622-9.622h-99.333v-4.848h99.335a14.475 14.475 0 0110.495 4.097 14.476 14.476 0 013.242 16.012 14.487 14.487 0 01-13.737 8.843h-.002z"
			></path>
			<path
				fill="#F8FAFB"
				d="M206.229 359.572a12.052 12.052 0 110-24.093h97.1v24.093h-97.1z"
			></path>
			<path
				fill="#CED7E0"
				d="M303.33 341.167H199.79v1.002h103.54v-1.002zM303.328 345.079H197.739v1.002h105.589v-1.002zM303.328 348.98H197.739v1.002h105.589v-1.002zM303.33 352.87H199.779v1.002H303.33v-1.002z"
			></path>
			<path
				fill="#DADFE8"
				stroke="#595959"
				strokeWidth="0.1"
				d="M305.566 362.006h-99.337a14.484 14.484 0 01-14.089-14.481 14.487 14.487 0 0114.089-14.48h99.337v4.858h-99.338a9.616 9.616 0 100 19.234h99.338v4.869z"
			></path>
			<path
				fill="#F8FAFB"
				d="M190.048 330.51a12.05 12.05 0 01-8.755-20.695 12.052 12.052 0 018.755-3.397h97.1v24.092h-97.1z"
			></path>
			<path
				fill="#CED7E0"
				d="M287.136 312.28h-103.54v.664h103.54v-.664zM287.147 316.181H181.558v.665h105.589v-.665zM287.147 320.082H181.558v.665h105.589v-.665zM287.147 323.983H183.596v.665h103.551v-.665z"
			></path>
			<path
				fill="#DADFE8"
				stroke="#595959"
				strokeWidth="0.1"
				d="M190.047 304.016h99.338v4.858h-99.338a9.62 9.62 0 00-6.801 2.816 9.62 9.62 0 000 13.601 9.617 9.617 0 006.801 2.817h99.338v4.869H190.045a14.482 14.482 0 01-14.882-14.481 14.491 14.491 0 019.218-13.494 14.482 14.482 0 015.664-.986h.002z"
			></path>
			<path
				fill="#C1C7D0"
				d="M367.772 172.149c-.425-3.008-1.755-7.345-2.452-10.701-.697-3.356-6.898-3.116-5.263 1.558a66.832 66.832 0 012.332 8.249 2.176 2.176 0 002.288 2.234c2.441.163 3.193-.915 3.095-1.34z"
			></path>
			<path
				fill="#FFC8AF"
				d="M368.295 132.801c-3.563-.294-3.814-20.235-3.269-28.702.545-8.466 2.572-15.037 6.919-14.667 4.348.37 5.242 7.17 4.359 15.615s-4.446 28.059-8.009 27.754z"
			></path>
			<path
				fill="#C1C7D0"
				d="M385.295 169.577c-2.931 1.188-13.741-24.626-16.095-30.51-2.353-5.884-4.358-12.183-1.089-13.512 3.269-1.329 6.233 4.653 8.586 10.493 2.354 5.841 11.54 32.44 8.598 33.529z"
			></path>
			<path
				fill="#C1C7D0"
				d="M387.135 172.737c-1.929 1.329-5.917 2.179-6.277 1.503-.359-.675.251-2.56.807-3.933.163-.403.316-.763.436-1.025.119-.261.239-.533.239-.533s-6.385-25.237-5.208-25.553c1.176-.316 9.807 24.703 9.807 24.703s0 .152.12.381l.098.316.642 2.179c.12.251.752.981-.664 1.962z"
			></path>
			<path
				fill="#253858"
				d="M387.136 172.737c-1.928 1.33-5.916 2.18-6.276 1.504-.36-.675.251-2.561.806-3.934.164-.403.316-.762.436-1.024.12.251.305.621.447.959.251.578.588.578 1.765-.196a23.536 23.536 0 012.866-1.449s.763 1.601.97 2.179c.109.218.403.981-1.014 1.961z"
			></path>
			<path
				fill="#FFC8AF"
				d="M385.414 78.318c-2.375.991-9.403-12.292-11.986-18.154-2.583-5.863-3.531-10.897-.632-12.15 2.899-1.253 5.819 3.062 8.172 9.012 2.354 5.95 6.8 20.3 4.446 21.292z"
			></path>
			<path
				fill="#FFC8AF"
				d="M371.956 102.857c-2.07-.948 4.359-16.498 6.342-20.813 1.983-4.315 4.359-8.717 6.713-7.627 2.353 1.09.555 5.764-1.428 10.09s-9.545 19.298-11.627 18.35z"
			></path>
			<path
				fill="#FFC8AF"
				d="M373.666 98.858c2.539.937.37 5.208-.425 6.636-1.286 2.332-4.882 3.487-5.797 2.996-.916-.49 2.397-5.535 2.288-5.829-.109-.295-1.624 2.593-2.43 2.048-.806-.545 1.199-3.966 2.506-5.012 1.308-1.046 2.278-1.428 3.858-.84zM351.297 133.869c-3.498-.763-1.145-20.573.501-28.898 1.645-8.325 4.511-14.569 8.772-13.632 4.26.938 4.26 7.792 2.299 16.051-1.962 8.26-8.086 27.242-11.572 26.479z"
			></path>
			<path
				fill="#C1C7D0"
				d="M365.637 171.604c-3.029.959-12.237-25.509-14.166-31.524-1.929-6.015-3.41-12.487 0-13.555 3.411-1.068 5.852 5.132 7.737 11.136 1.885 6.004 9.447 33.006 6.429 33.943z"
			></path>
			<path
				fill="#253858"
				d="M367.772 172.149v2.975a.542.542 0 01-.545.544h-9.578a.864.864 0 01-.821-.589.867.867 0 01.287-.969 37.555 37.555 0 015.263-2.898c.392.348.992 1.405 1.678 1.536a19.72 19.72 0 003.716-.599z"
			></path>
			<path
				fill="#C1C7D0"
				d="M326.235 59.27c1.492-1.7 14.438 8.827 17.968 11.986 3.531 3.16 7.05 6.647 5.372 8.554s-5.633-1.09-9.175-4.217c-3.541-3.127-15.658-14.612-14.165-16.323z"
			></path>
			<path
				fill="#C1C7D0"
				d="M328.902 61.069c-.675 1.972-4.021.381-5.143-.197a4.36 4.36 0 01-1.765-4.51c.359-.71 3.269 2.08 3.476 1.982.207-.098-1.624-1.438-1.221-2.07.404-.632 3.084.84 3.923 1.83a2.673 2.673 0 01.73 2.964z"
			></path>
			<path
				fill="#FFC8AF"
				d="M376.402 97.376l-22.186-.926-.969-37.953 1.525-9.535 10.254-3.77 6.538 1.264a7.75 7.75 0 016.287 7.878l-2.408 26.86.959 16.182z"
			></path>
			<path
				fill="#FFC8AF"
				d="M367.498 40.517v7.627s-1.613.97-5.383.97c-2.038 0-2.496-.97-2.496-.97v-2.789l7.879-4.838z"
			></path>
			<path
				fill="#C1C7D0"
				d="M368.382 34.83v1.057c.185 4.532-2.725 9.382-7.094 10.035h-.251c-.937.11-4.358.393-5.557-.98a11.463 11.463 0 01-2.746-6.538c.051-1.097.171-2.188.36-3.27 0-.642-.338-2.615-.349-3.475 0-4.043 2.691-8.053 7.529-8.053 7.214-.044 8.315 6.353 8.108 11.224z"
			></path>
			<path
				fill="#D3807C"
				d="M354.086 48.711c-1.547 1.428-2.746 3.934-3.04 9.055-.076 1.428-1.09 5.35.784 10.134 3.378 8.445 1.308 18.906 1.308 18.906l22.981.85s.49-15.408 1.275-20.333c2.365-14.83 0-19.614-4.152-20.704-6.189-1.798-17.62.665-19.156 2.092z"
			></path>
			<path
				fill="#FFC8AF"
				d="M348.364 80.312c-2.474-.643-.098-15.354 1.373-21.521 1.471-6.168 3.727-10.777 6.756-9.992 3.029.784 2.768 5.895 1.09 11.986-1.679 6.091-6.713 20.159-9.219 19.527z"
			></path>
			<path
				fill="#253858"
				d="M376.36 81.642c-.196 4.816 3.443 11.147 4.01 15.952 1.09 9.622-5.536 27.645-5.536 28.811 0 1.166 6.233 16.803 8.642 26.337 0 0-1.352 1.951-7.879 3.139 0 0-10.003-21.794-10.896-26.697-.894-4.904.217-46.649.217-46.649l11.442-.893z"
			></path>
			<path
				fill="#253858"
				d="M366.246 82.404H353.66a235.564 235.564 0 00-4.762 26.839c-1.863 15.429-1.264 20.006-.926 22.458.338 2.451 8.837 25.683 8.837 25.683a14.889 14.889 0 008.772-1.972s-2.866-13.218-5.328-19.32a61.326 61.326 0 01-2.725-7.464s7.083-15.081 8.848-25.847a63.611 63.611 0 00-.13-20.377z"
			></path>
			<path
				fill="#EBECF0"
				d="M386.733 76.28c-.447 4.707-3.356 10.287-5.568 13.915a76.043 76.043 0 01-1.994 3.073c-.327.48-.534.752-.534.752l-.12-.403-1.82-6.92-.501-1.863.174-.294 2.55-4.511 2.572-4.512-1.21-1.83-.773-1.242-3.269-5.274-1.155-1.831 2.179-16.138s10.199 19.385 9.469 27.078z"
			></path>
			<path
				fill="#DFE5EA"
				d="M379.497 72.444l-3.291-5.274 1.537-15.974 1.754 21.248zM381.165 90.196a77.168 77.168 0 01-1.994 3.073l-.654.348-1.82-6.92-.327-2.178 2.55-4.512a53.566 53.566 0 012.245 10.189z"
			></path>
			<path
				fill="#EBECF0"
				d="M379.627 111.553c-.098.098-32.188.588-32.188.588a229.484 229.484 0 005.197-31.992A38.426 38.426 0 00352.081 69c-.077-.446-.164-.904-.251-1.383a52.813 52.813 0 010-16.247 6.855 6.855 0 015.448-4.272c2.321-.48 10.287-1.384 10.287-1.384 4.097-.24 9.676 1.384 10.232 5.056.839 5.623.599 16.127-.12 24.071-.719 7.944 1.678 10.101 2.887 18.884 1.21 8.783-.457 17.336-.937 17.827z"
			></path>
			<path
				fill="#DFE5EA"
				d="M352.637 80.148c.358-3.723.17-7.478-.556-11.147 2.931-5.83 7.693-15.158 7.693-15.158s-2.136 16.204-7.137 26.305z"
			></path>
			<path
				fill="#EBECF0"
				d="M356.712 65.994s-4.609 14.71-7.857 14.71c-3.247 0-6.865-3.269-10.199-6.048-3.334-2.778-9.022-8.935-9.022-8.935l3.694-4.359 13.076 10.2s3.432-19.767 6.047-21.848c2.615-2.081 8.718-2.114 7.628 4.239a104.422 104.422 0 01-3.367 12.04z"
			></path>
			<path
				fill="#1D1D1D"
				d="M351.351 29.152c0 .24.087.48.141.73.502 2.027 2.082 3.204 1.929 5.448a9.255 9.255 0 001.242 5.721c2.441 4.141-.926 3.27-.25 9.578.327 3.117 3.269 4.36 9.142 4.36 5.873 0 12.302-.164 12.128-4.578a8.078 8.078 0 00-1.613-4.358c-1.089-1.7-2.081-3.127-1.471-5.22.317-.852.54-1.736.665-2.637.142-2.179-1.449-2.92-2.681-4.358-1.231-1.439-.544-4.152-.991-6.004-1.439-6.037-7.704-6.745-12.782-5.11-3.106.904-5.939 2.865-5.459 6.428z"
			></path>
			<path
				fill="#FFC8AF"
				d="M210.533 298.736c-.719-3.193 16.628-8.925 24.169-10.777 7.54-1.853 13.838-1.907 14.721 1.994.883 3.901-4.827 6.538-12.433 8.118s-25.727 3.857-26.457.665z"
			></path>
			<path
				fill="#FFC8AF"
				d="M213.649 307.257c-1.013-3.117 15.735-10.396 23.079-12.935 7.345-2.539 13.61-3.16 14.842.643 1.231 3.803-4.207 6.974-11.638 9.23-7.432 2.255-25.28 6.178-26.283 3.062z"
			></path>
			<path
				fill="#C1C7D0"
				d="M214.598 339.26l.141 2.91a.749.749 0 01-.751.741h-7.835a.732.732 0 01-.447-1.319 11.478 11.478 0 014.359-2.179c.131 0 1.242-24.561 2.43-24.572 1.187-.011 2.103 24.419 2.103 24.419z"
			></path>
			<path
				fill="#C1C7D0"
				d="M212.668 340.317c-2.91 0-3.705-25.651-3.705-31.415 0-5.765.446-11.856 3.705-11.856 3.258 0 3.693 6.091 3.693 11.856 0 5.764-.795 31.415-3.693 31.415z"
			></path>
			<path
				fill="#253858"
				d="M214.739 339.794v2.724a.503.503 0 01-.501.502h-8.772a.797.797 0 01-.479-1.428 33.538 33.538 0 014.827-2.637c.359.316.904 1.297 1.536 1.417 1.145-.086 2.28-.28 3.389-.578zM222.668 346.263v2.725a.502.502 0 01-.501.501h-8.772a.797.797 0 01-.479-1.428 33.636 33.636 0 014.827-2.637c.359.316.904 1.297 1.536 1.417a18.279 18.279 0 003.389-.578z"
			></path>
			<path
				fill="#C1C7D0"
				d="M219.426 348.141c-2.898.262-5.546-25.226-6.08-30.968-.534-5.743-.632-11.845 2.604-12.139 3.237-.294 4.25 5.721 4.773 11.463.523 5.743 1.591 31.383-1.297 31.644zM198.603 265.13c1.144-1.743 14.307 6.266 17.936 8.663 3.628 2.397 7.279 5.198 5.982 7.159-1.297 1.962-5.296-.305-8.925-2.702-3.628-2.397-16.148-11.365-14.993-13.12z"
			></path>
			<path
				fill="#C1C7D0"
				d="M201.335 266.557c-.708 2.093-4.238.414-5.448-.196-1.929-1.002-2.245-4.01-1.874-4.762.37-.751 3.443 2.18 3.672 2.093.229-.088-1.711-1.526-1.286-2.18.425-.654 3.269.894 4.141 1.94a2.812 2.812 0 01.795 3.105z"
			></path>
			<path
				fill="#FFC8AF"
				d="M220.984 282.271c-2.245-.632.228-14.057 1.71-19.68 1.482-5.622 3.64-9.807 6.386-9 2.746.806 2.419 5.448.741 11.016s-6.582 18.296-8.837 17.664z"
			></path>
			<path
				fill="#EBECF0"
				d="M204.179 271.81c.125.144.264.276.415.392 1.743 1.526 9.708 9.949 14.677 10.559.446.071.902.052 1.341-.054 4.707-1.166 8.553-15.081 8.553-15.081l.066-.175 3.639-9.807 8.892-6.69-6.843-1.635-.556 4.261-2.669-1.264-.807-.414s-3.498 1.242-4.358 2.179c-4.272 4.5-7.345 20.028-7.628 20.028 0 0-9.665-5.448-11.867-6.298l-2.855 3.999z"
			></path>
			<path
				fill="#DCE8EF"
				d="M219.273 282.76c.446.071.902.052 1.34-.054 4.708-1.166 8.554-15.081 8.554-15.081l.066-.174-2.452-8.402s1.198 9.382-.97 14.613a29.92 29.92 0 01-6.538 9.098z"
			></path>
			<path
				fill="#C1C7D0"
				d="M254.36 295.009l-19.015-3.106-5.329-27.241-2.539-9.993 8.315-5.971 6.124-.578a7.083 7.083 0 017.54 5.318s4.272 18.088 4.751 24.245c.445 5.765.496 11.554.153 17.326z"
			></path>
			<path
				fill="#253858"
				d="M235.039 287.577s-13.153 3.269-19.745 6.538c-6.593 3.269-7.094 5.656-7.312 12.455-.218 6.8 1.308 31.721 1.308 31.721s1.612 1.34 6.124.958c0 0 2.397-25.683 2.441-31.36.043-5.677 28.08-9.546 28.08-9.546s3.836-6.81-.577-9.055c-4.413-2.244-10.319-1.711-10.319-1.711z"
			></path>
			<path
				fill="#253858"
				d="M253.932 296.36c-.218 3.432-9.698 9.741-19.113 11.55-9.414 1.809-12.237 2.016-12.389 2.376-.153.359 0 36.427 0 36.427a5.35 5.35 0 01-6.07-.719s-3.759-26.217-3.693-33.605c.065-7.388 2.07-8.62 4.543-9.807 2.474-1.188 22.883-11.921 22.883-11.921l13.839 5.699z"
			></path>
			<path
				fill="#C1C7D0"
				d="M236.674 243.577l1.951 5.339a7.29 7.29 0 01-4.49 3.596c-1.787.512-2.441-.218-2.441-.218l-.719-2.474 5.699-6.243z"
			></path>
			<path
				fill="#F2B49C"
				d="M227.019 239.828c-.632.382-.24 2.735-.12 2.692.12-.044 0-1.983.545-2.43.544-.447-.425-.262-.425-.262zM224.492 240.646c.512.348.698 2.103.73 2.255.033.153-.773-1.961-.773-1.961l.043-.294z"
			></path>
			<path
				fill="#414042"
				d="M229.928 239.077l.076.272s-2.855.839-2.887.817c-.033-.021-.175-.218-.098-.337.392-.632 2.909-.752 2.909-.752zM222.445 241.245l.076.283s2.005-.589 2.016-.621a.401.401 0 00-.109-.338c-.523-.349-1.983.676-1.983.676zM229.385 241.169a.609.609 0 01-.414.741.603.603 0 01-.688-.419.598.598 0 01.35-.726.603.603 0 01.752.404z"
			></path>
			<path
				fill="#414042"
				d="M229.864 240.525a2.898 2.898 0 00-1.297 0 2.694 2.694 0 00-1.09.687l2.387-.687zM224.633 242.585a.594.594 0 01-.529.76.601.601 0 01-.212-1.174.617.617 0 01.457.053.605.605 0 01.284.361z"
			></path>
			<path
				fill="#414042"
				d="M224.765 242.007a1.493 1.493 0 00-.937-.087 1.406 1.406 0 00-.73.578l1.667-.491z"
			></path>
			<path
				fill="#E0BD96"
				d="M226.606 245.124c.577-.163 1.035-.349 1.089-.196.055.152-.37.49-.948.654-.577.163-1.089.109-1.144 0-.054-.109.425-.295 1.003-.458z"
			></path>
			<path
				fill="#F2B49C"
				d="M227.292 247.488c.425-.131.752-.261.795-.152.044.108-.24.479-.664.61-.425.131-.84 0-.872-.164-.033-.163.316-.174.741-.294zM225.08 247.118a.13.13 0 00-.098.131.131.131 0 00.042.096.146.146 0 00.046.028c.018.006.036.008.054.006a8.647 8.647 0 002.037-.283 9.226 9.226 0 001.918-.861.152.152 0 000-.196.163.163 0 00-.196 0 8.205 8.205 0 01-1.841.839 8.702 8.702 0 01-1.973.272l.011-.032z"
			></path>
			<path
				fill="#C1C7D0"
				d="M235.999 238.346c.055.251.109.479.164.697.054.218.054.164.076.24 1.318 3.934 0 8.946-3.672 10.646h-.087l-.131.055c-.796.337-3.694 1.449-5.132.566a10.667 10.667 0 01-4.098-5.067 23.178 23.178 0 01-.512-2.996c-.163-.556-.97-2.18-1.198-2.964-1.09-3.542.316-7.759 4.565-9.001 6.364-1.809 8.957 3.487 10.025 7.824z"
			></path>
			<path
				fill="#C1C7D0"
				d="M230.234 250.242c1.95-.469 3.977-3.095 4.434-4.163.275-1.1.341-2.242.197-3.367a1.207 1.207 0 00.86-.806c.118.467.099.959-.054 1.416-.283.436-.512.578-.523.839-.011.262.425 3.4-.926 4.719a6.932 6.932 0 01-3.988 1.362z"
			></path>
			<path
				fill="#1D262D"
				d="M222.302 235.077s1.841.883 4.358 2.31a10.165 10.165 0 004.359 1.482l2.593 1.885 1.188-.588 1.874 3.411c.222-1.918.244-3.853.066-5.776-.305-3.007-2.06-8.237-5.525-9.153-3.465-.915-10.243 3.672-10.962 4.904-.719 1.231-1.787 3.269 1.384 6.614a7.423 7.423 0 01-.425-3.269c.251-1.58 1.09-1.82 1.09-1.82z"
			></path>
			<path
				fill="#C1C7D0"
				d="M234.145 238.945c.468-.272 1.547-1.09 1.841.806.512 3.171-1.34 3.498-1.34 3.498 0-.468 0-1.089-.109-1.874-.578-.152-.055-.708-.142-1.09a.548.548 0 000-.228c-.087-.349-.163-.741-.25-1.112z"
			></path>
			<path
				fill="#1D262D"
				d="M232.633 237.987c.33.809.596 1.644.795 2.495.106.476.241.946.403 1.406.087.229.24 1.024.534 1.089.294.066.066-2.179 0-2.495.002-.65-.057-1.3-.174-1.939l-1.558-.556z"
			></path>
			<path
				fill="#1753E2"
				d="M254.59 296.36c-14.242-1.602-21.925-8.347-21.925-8.347s-1.558-13.719-2.429-17.435c-.872-3.716-3.934-11.561-3.509-14.307a4.994 4.994 0 013.672-4.184s-.055-.491.981-.938l.392 1.243 2.288 1.961.73-4.032.175-1.089.534.762c1.209-.468 2.528-1.285 3.792-1.71a19.218 19.218 0 015.317-.97c3.389-.087 6.2 8.717 8.783 21.63 2.582 12.912 1.199 27.416 1.199 27.416z"
			></path>
			<path
				fill="#EBECF0"
				d="M229.375 298.713l9.807 11.213s15.909-6.44 16.345-17.108a100.836 100.836 0 00-.6-15.255c-.076-.741-.152-1.46-.239-2.179a149.507 149.507 0 00-2.398-13.796c-1.939-8.717-3.552-12.204-5.568-13.729-2.016-1.526-7.148.196-7.279.283-.131.087-3.051 6.538-3.171 12.422-.229 10.951 1.896 16.901 1.58 25.302a12.253 12.253 0 01-.327 2.419 12.967 12.967 0 01-1.253 3.335 18.999 18.999 0 01-6.897 7.093z"
			></path>
			<path
				fill="#DCE8EF"
				d="M236.304 291.619a69.844 69.844 0 0012.466-8.041 28.398 28.398 0 006.19-6.026c-.077-.741-.153-1.46-.24-2.179l-14.602 7.965-2.56 5.012a12.928 12.928 0 01-1.254 3.269zM243.277 262.101s2.506 10.102 1.21 11.801c-1.297 1.7-8.565 11.322-8.565 11.322s13.653-10.755 13.49-11.986c-.163-1.232-6.135-11.137-6.135-11.137z"
			></path>
			<path
				fill="#EBECF0"
				d="M230.224 252.163c.098 3.104.423 6.195.969 9.252.883 4.892 4.359 19.091 3.564 25.443-.796 6.353-7.868 9.164-7.868 9.164l-3.018-5.709s7.497-.818 7.628-2.485a107.828 107.828 0 00-1.787-16.541c-1.232-5.35-3.062-10.123-3.269-13.425a5.206 5.206 0 013.781-5.699z"
			></path>
			<path
				fill="#1753E2"
				d="M230.298 250.953l1.439 1.471-.033-.109-.501-1.711-.381.055-.524.294zM234.92 249.308l1.722 2.473c1.089-1.547 2.604-3.432 2.604-3.432l-1.613-1.406-2.713 2.365zM230.375 251.913l.131 1.656 1.242-1.112-.011-.032-1.439-1.471.077.959z"
			></path>
			<path
				fill="#8C8C8C"
				d="M230.681 292.35l-4.075.73 2.059 5.579 17.272-3.858-15.256-2.451z"
			></path>
			<path
				fill="#8C8C8C"
				d="M209.064 296.24l19.614 2.419-6.887-18.524c-.25 0-19.232-.425-19.232-.425l6.505 16.53z"
			></path>
			<path
				fill="#595959"
				d="M209.062 296.24l.065 1.275 3.302.447 12.683 1.787 3.684.523-.088-1.612-19.646-2.42zM228.708 298.659l.088 1.613 17.009-4.228.077-1.242-17.174 3.857z"
			></path>
			<path
				fill="#595959"
				d="M202.59 279.655l.806-.36 18.96.48 6.952 18.753-.599.131-6.887-18.579-19.232-.425z"
			></path>
			<path
				fill="#FFC8AF"
				d="M252.472 276.713c-2.255.687-7.442-12.062-9.24-17.641-1.798-5.579-2.179-10.287.545-11.126 2.724-.839 5.012 3.324 6.614 8.968 1.602 5.645 4.261 19.124 2.081 19.799z"
			></path>
			<path
				fill="#C1C7D0"
				d="M230.998 292.143c-1.34-1.602 10.079-11.986 13.414-14.732 3.334-2.746 7.05-5.448 8.554-3.705 1.503 1.744-1.798 4.991-5.122 7.791-3.323 2.801-15.495 12.303-16.846 10.646z"
			></path>
			<path
				fill="#C1C7D0"
				d="M233.95 289.506c1.732 1.766-1.744 4.457-2.953 5.329-1.984 1.427-5.154.218-5.721-.545-.567-.763 3.945-2.332 3.966-2.615.022-.283-2.408.948-2.855.174-.446-.773 2.55-2.811 4.043-3.16a3.155 3.155 0 013.52.817z"
			></path>
			<path
				fill="#EBECF0"
				d="M235.594 285.344l2.833 3.672c.643-.294 16.149-11.158 16.149-13.708 0-2.55-2.844-17.239-4.882-22.611-2.037-5.372-4.674-5.742-6.44-5.045-3.269 1.253-2.593 6.178-1.972 9.458.621 3.28 5.982 16.127 5.797 16.411-.185.283-3.836 3.966-6.396 6.538-1.82 1.809-4.163 4.053-5.089 5.285z"
			></path>
			<path
				fill="#FFC8AF"
				d="M53.914 348.74c3.27-.109 4.5-18.524 4.36-26.348-.143-7.824-1.646-14.002-5.689-13.871-4.043.131-5.176 6.374-4.773 14.165.403 7.792 2.8 26.163 6.102 26.054zM64.452 346.822c3.269-.109 4.5-18.524 4.358-26.348-.141-7.824-1.645-14.002-5.677-13.871-4.032.13-5.176 6.374-4.784 14.165.393 7.791 2.801 26.163 6.103 26.054z"
			></path>
			<path
				fill="#C1C7D0"
				d="M61.63 389.101l-.142 2.931a.764.764 0 00.763.752h7.9a.752.752 0 00.458-1.34 11.68 11.68 0 00-4.359-2.18c-.13 0-1.253-28.331-2.451-28.331-1.199 0-2.169 28.168-2.169 28.168z"
			></path>
			<path
				fill="#C1C7D0"
				d="M63.581 390.17c2.931 0 3.738-29.476 3.738-35.295 0-5.819-.447-11.986-3.738-11.986-3.29 0-3.737 6.146-3.737 11.986 0 5.841.85 35.295 3.737 35.295z"
			></path>
			<path
				fill="#253858"
				d="M61.489 389.69v2.756a.513.513 0 00.5.502h8.86a.81.81 0 00.774-1.044.814.814 0 00-.284-.406 34.276 34.276 0 00-4.882-2.647c-.36.316-.904 1.296-1.547 1.416a17.71 17.71 0 01-3.421-.577z"
			></path>
			<path
				fill="#C1C7D0"
				d="M48.542 390.365v2.768c.18.644.586 1.201 1.145 1.569 1.994.85 5.818.349 5.97-.338.153-.686-.914-2.179-1.775-3.269-.556-.697-1.014-1.198-1.014-1.198s-1.09-27.623-2.179-27.58c-1.09.044-2.147 28.048-2.147 28.048z"
			></path>
			<path
				fill="#C1C7D0"
				d="M50.484 391.455c2.92.262 5.6-29.039 6.134-34.869.534-5.83.643-11.986-2.626-12.259-3.269-.272-4.282 5.775-4.816 11.572-.534 5.798-1.558 35.284 1.308 35.556z"
			></path>
			<path
				fill="#253858"
				d="M48.542 390.78v2.408c.18.644.586 1.201 1.145 1.569 1.994.85 5.818.349 5.97-.338.153-.686-.914-2.179-1.775-3.269a5.227 5.227 0 00-2.866 0 6.834 6.834 0 01-1.351.643 7.064 7.064 0 01-1.123-1.013z"
			></path>
			<path
				fill="#C1C7D0"
				d="M121.158 288.351c0-2.114-15.474-3.073-19.865-3.182-4.391-.109-9.022.109-9.077 2.474-.054 2.364 4.566 2.811 8.946 2.909 4.381.098 19.941-.022 19.996-2.201z"
			></path>
			<path
				fill="#FFC8AF"
				d="M97.882 289.789c1.09-2.092-10.406-9.665-15.527-12.509-5.122-2.844-9.677-4.184-11.028-1.634-1.35 2.549 2.289 5.568 7.508 8.237 5.22 2.67 17.936 7.988 19.047 5.906z"
			></path>
			<path
				fill="#C1C7D0"
				d="M117.215 288.013c-.599 2.353 3.509 2.844 4.947 3.138 1.863.37 3.084-1.961 3.193-2.888.109-.926-2.91-.479-3.062-.708-.153-.229 2.506-.37 2.517-1.242.011-.872-3.542-1.144-4.991-.708a3.08 3.08 0 00-2.604 2.408z"
			></path>
			<path
				fill="#C1C7D0"
				d="M119.818 285.605c1.449-.436 5.001-.174 4.99.708-.011.883-2.669 1.014-2.517 1.243l-3.519-1.559a6.358 6.358 0 011.046-.392zM125.287 288.013a2.37 2.37 0 00-1.71-.6c-.534 0-1.09.839-1.569 1.656-.48.818 1.296 1.853 1.296 1.853l1.232-1.231.751-1.678z"
			></path>
			<path
				fill="#EBECF0"
				d="M113.651 290.693a7.798 7.798 0 01-2.005.262c-2.561.087-12.934.828-17.685-.327-3.574-.872-12.15-3.269-16.182-4.457l-2.18-.621-2.625-.664-9.6-2.43-2.528-11.442 6.897-1.667.556 4.304 2.692-1.286.817-.414 4.358 2.18a119.647 119.647 0 0118.449 10.133s15.397 1.09 18.785 1.417l.251 5.012z"
			></path>
			<path
				fill="#DFE5EA"
				d="M77.778 286.172l-2.18-.622-2.626-.664c.643-2.387 2.811-10.526 2.811-10.526a6.533 6.533 0 011.493 5.666 17.143 17.143 0 00.502 6.146z"
			></path>
			<path
				fill="#C1C7D0"
				d="M48.139 315.484l19.189-3.16 5.35-27.504 2.561-10.079-8.39-6.026-6.19-.588c-3.508-.327-5.84 1.819-6.712 5.241 0 0-5.198 18.383-5.688 24.594a127.363 127.363 0 00-.12 17.522z"
			></path>
			<path
				fill="#C1C7D0"
				d="M65.99 263.572l-1.962 5.372a7.304 7.304 0 004.522 3.64c1.81.523 2.463-.218 2.463-.218l.73-2.506-5.753-6.288z"
			></path>
			<path
				fill="#F2B49C"
				d="M75.73 259.78c.644.381.24 2.757.12 2.713-.12-.043 0-1.994-.544-2.451-.545-.458.425-.262.425-.262zM78.28 260.608c-.512.349-.697 2.114-.73 2.267-.032.152.785-1.973.785-1.973l-.054-.294z"
			></path>
			<path
				fill="#414042"
				d="M72.801 259.017l-.076.272s2.888.85 2.91.839c.021-.011.184-.229.097-.348-.403-.643-2.93-.763-2.93-.763zM80.36 261.207l-.087.283s-2.016-.599-2.027-.621a.402.402 0 01.099-.348c.533-.349 2.015.686 2.015.686zM73.357 261.109a.603.603 0 00.202.645.604.604 0 00.212.107.581.581 0 00.459-.049.59.59 0 00.282-.365.602.602 0 00-1.155-.338z"
			></path>
			<path
				fill="#414042"
				d="M72.863 260.488a2.401 2.401 0 012.408.698l-2.408-.698zM78.128 262.526a.609.609 0 00.887.748.616.616 0 00.279-.41.61.61 0 00-1.166-.338z"
			></path>
			<path
				fill="#414042"
				d="M78.018 261.981a1.48 1.48 0 01.937-.087c.305.104.566.307.74.577l-1.677-.49z"
			></path>
			<path
				fill="#E0BD96"
				d="M76.144 265.13c-.578-.174-1.09-.359-1.09-.196 0 .164.37.479.948.654.578.174 1.09.109 1.155 0 .066-.109-.425-.294-1.013-.458z"
			></path>
			<path
				fill="#F2B49C"
				d="M75.46 267.506c-.426-.12-.764-.262-.796-.142-.033.12.228.48.664.611.436.13.85 0 .883-.164.033-.163-.327-.163-.752-.305zM77.692 267.135a.131.131 0 01.098.13.143.143 0 01-.041.101.144.144 0 01-.1.041 8.532 8.532 0 01-2.06-.294 8.98 8.98 0 01-1.929-.872.135.135 0 01.142-.229 9.239 9.239 0 001.863.839 8.832 8.832 0 001.994.273l.033.011z"
			></path>
			<path
				fill="#C1C7D0"
				d="M66.676 258.287c-.065.25-.12.479-.163.708-.044.229 0 .152-.088.24-1.329 3.966 0 9.022 3.716 10.733H70.36c.796.349 3.727 1.471 5.187.577a10.688 10.688 0 004.119-5.11c.243-.993.417-2.002.523-3.019.163-.566.98-2.179 1.21-2.996 1.09-3.574-.316-7.835-4.599-9.088-6.44-1.776-9.055 3.574-10.123 7.955z"
			></path>
			<path
				fill="#262626"
				d="M69.53 259.518c.653-1.275 2.016-1.558 2.942-2.506.314-.415.54-.89.664-1.395.807-1.874 2.376-1.155 3.901-.556.883.338 1.722.927 2.626 1.188a6.643 6.643 0 004.207-.632 2.268 2.268 0 001.71-2.506 2.177 2.177 0 00-1.09-1.754 3.266 3.266 0 00-1.863-.24c-.893 0-1.187-.305-1.797-.937a3.727 3.727 0 00-1.799-1.308 8.423 8.423 0 00-1.808.087 3.5 3.5 0 01-1.787-.621c-4.653-2.571-8.533 3.269-9.807 7.007a18.09 18.09 0 00-.142 9.306l2.942-5.242 1.1.109z"
			></path>
			<path
				fill="#C1C7D0"
				d="M68.539 258.929c-.48-.272-1.558-1.035-1.853.818-.523 3.192 1.352 3.53 1.352 3.53 0-.468 0-1.09.109-1.896.577-.152 0-.719.141-1.09a.572.572 0 010-.228c.087-.404.164-.774.25-1.134z"
			></path>
			<path
				fill="#11477C"
				d="M70.063 257.917c-.324.82-.593 1.661-.806 2.517-.103.483-.234.96-.392 1.427-.098.229-.25 1.036-.545 1.09-.294.054-.066-2.234 0-2.528-.001-.654.057-1.307.174-1.951l1.57-.555z"
			></path>
			<path
				fill="#C1C7D0"
				d="M72.494 270.764c-1.972-.48-4.021-3.138-4.479-4.206a9.056 9.056 0 01-.196-3.4 1.192 1.192 0 01-.872-.806c-.123.467-.104.96.055 1.416.283.447.512.589.523.85.01.262-.425 3.432.948 4.762a6.912 6.912 0 004.02 1.384z"
			></path>
			<path
				fill="#253858"
				d="M47.127 316.922s1.405 22.567 1.405 25.063c0 2.495-1.09 38.192-.425 47.117a8.726 8.726 0 005.59 0s3.204-32.418 3.498-39.359c.273-6.538 1.515-20.115 1.515-20.115s.686 9.338.545 12.422c-.142 3.084-.088 36.221 1.492 46.518a8.718 8.718 0 005.667-.545s1.362-38.694 1.907-45.624c.544-6.931.959-26.893.959-26.893l-21.369-.785-.784 2.201z"
			></path>
			<path
				fill="#1753E2"
				d="M46.692 316.846c15.659 2.866 23.373 0 23.373 0s1.548-22.273 2.43-25.978c.883-3.705 3.967-11.681 3.53-14.449a5 5 0 00-3.704-4.217s.065-.501-.98-.948l-.36 1.242-2.31 1.984-.73-4.076-.186-1.089-.523.762c-1.231-.468-2.56-1.296-3.846-1.732a19.613 19.613 0 00-5.361-.981c-3.422-.076-6.255 8.772-8.87 21.793a156.179 156.179 0 00-2.463 27.689z"
			></path>
			<path
				fill="#EBECF0"
				d="M46.798 337.32s-1.558-24.147.131-38.051c1.69-13.904 6.538-28.789 8.053-30.772 2.005-2.604 7.627-.523 7.627-.523s5.841 6.538 5.133 15.386c-.447 5.547-4.097 15.397-4.49 26.948-.392 11.55-.555 26.609-.555 26.609l-15.899.403zM76.231 279.492c-1.427 6.015-3.268 11.245-4.053 17.315-.087.566-.13 1.144-.185 1.732-.066.763-.12 1.559-.185 2.376-1.09 13.991-2.18 35.959-2.18 35.959l-4.01-.076s0-25.836 1.591-36.221v-.381c.24-1.526.48-2.921.73-4.228 1.406-7.312 2.953-11.137 3.454-13.316.517-3.514.845-7.053.981-10.602a5.87 5.87 0 013.858 7.442z"
			></path>
			<path
				fill="url(#paint0_linear_8413_44840)"
				d="M113.564 256.751l-1.177 3.269-15.876 44.512-.894 2.496-6.68 18.731a7.229 7.229 0 01-3.681 4.292 7.243 7.243 0 01-5.645.332 7.245 7.245 0 01-4.16-3.831 7.233 7.233 0 01-.134-5.653l3.051-8.554.61-1.71 19.788-55.475 1.177-3.269 13.621 4.86z"
			></path>
			<path
				fill="#F4A362"
				d="M89.2 287.414l-12.238 34.139a5.482 5.482 0 003.324 7.006 5.482 5.482 0 007.006-3.323l13.49-37.822H89.199z"
			></path>
			<path
				fill="#F0F0F0"
				d="M98.327 252.528l-.432 1.212a1.111 1.111 0 00.674 1.42l14.009 4.997a1.112 1.112 0 001.42-.674l.432-1.211a1.111 1.111 0 00-.673-1.42l-14.01-4.997a1.111 1.111 0 00-1.42.673z"
			></path>
			<path
				fill="#C3D3DD"
				d="M84.252 316.486a1.09 1.09 0 101.666-1.403 1.09 1.09 0 00-1.666 1.403zM96.14 291.337a.444.444 0 00.493.035.449.449 0 00-.099-.818.447.447 0 00-.395.783zM90.039 310.536a.444.444 0 00.632-.076.435.435 0 00-.077-.621.452.452 0 00-.336-.114.443.443 0 00-.313.166.45.45 0 00.094.645zM82.486 321.041a.444.444 0 00.492.038.456.456 0 00.065-.747.45.45 0 00-.557.709zM83.575 307.093a.45.45 0 00.62-.066.44.44 0 00.095-.332.455.455 0 00-.17-.3.45.45 0 00-.577.112.447.447 0 00.031.586zM89.21 295.51a.43.43 0 00.326.093.435.435 0 00.294-.169.445.445 0 00-.076-.632.43.43 0 00-.49-.038.439.439 0 00-.13.114.447.447 0 00.075.632zM88.02 303.988a1.723 1.723 0 10-.6-1.82 1.713 1.713 0 00.6 1.82z"
				opacity="0.5"
			></path>
			<path
				fill="#C3D3DD"
				d="M84.885 300.337c-.436.087-.861.196-1.286.316-.589.164-1.199.316-1.776.512-.31.156-.64.266-.981.327l-7.377-.468-1.656-.109a37.493 37.493 0 01-4.522-.676 8.642 8.642 0 01-3.52-1.351c-2.452-2.027-4.359-6.603-5.765-11.06a79.837 79.837 0 01-.784-2.681c-1.22-4.533-1.852-8.336-1.852-8.336l4.598 7.421 1.504 2.43 5.677 9.186.872.163 1.09.207-.59-1.013 3.89.392 8.032.806c3.716.382 7.42.687 11.104 1.308 1.255.184 2.494.468 3.704.85a.91.91 0 01.436.251.632.632 0 01.066.621 1.553 1.553 0 01-.382.534 2.506 2.506 0 01-1.21.806 2.705 2.705 0 01-.73 0 20.932 20.932 0 01-3.094-.185c-.926-.153-1.83-.414-2.757-.491a9.482 9.482 0 00-2.691.24z"
			></path>
			<path
				fill="#1753E2"
				d="M72.418 271.003l-1.45 1.493.033-.12.501-1.721.393.054.523.294zM67.766 269.347l-1.744 2.506c-1.09-1.569-2.637-3.476-2.637-3.476l1.406-1.678 2.975 2.648z"
			></path>
			<path
				fill="#1753E2"
				d="M72.353 271.973l-.142 1.678-1.253-1.122.01-.033 1.45-1.493-.065.97z"
			></path>
			<path
				fill="#FFC8AF"
				d="M65.835 297.297c2.278-.686-.523-14.296-2.18-19.984-1.656-5.688-3.911-9.884-6.69-9.034-2.779.85-2.354 5.601-.523 11.224 1.83 5.623 7.116 18.491 9.393 17.794z"
			></path>
			<path
				fill="#C1C7D0"
				d="M92.468 298.223c.229-2.092-15.157-4.359-19.516-4.838-4.358-.479-9-.665-9.262 1.689-.261 2.354 4.359 3.182 8.717 3.661 4.36.48 19.887 1.602 20.061-.512z"
			></path>
			<path
				fill="#C1C7D0"
				d="M88.48 297.689c-.448 2.452 3.966 2.757 5.448 2.812 2.462.087 2.495-2.627 2.538-3.575.044-.948-2.67.164-2.844-.065-.174-.229 2.55-.545 2.496-1.449-.055-.905-3.716-.926-5.165-.382-1.45.545-2.19 1.123-2.474 2.659z"
			></path>
			<path
				fill="#EBECF0"
				d="M84.916 294.987l-.13 4.87c-4.36 0-18.765 0-21.108-2.277-2.67-2.626-8.248-18.59-9.284-22.556-1.035-3.966 0-7.366 3.487-7.628 3.487-.261 5.013 4.163 6.146 7.868s4.293 17.042 4.293 17.042 15.114 2.474 16.596 2.681z"
			></path>
			<path
				fill="#A6BFF7"
				d="M215.872 5.234a14.003 14.003 0 0018.742 20.355l8.957 8.957a.97.97 0 001.373 0l2.507-2.517a.963.963 0 00.21-1.05.964.964 0 00-.21-.312l-8.946-8.957a14.015 14.015 0 00-22.633-16.476zm16.988 14.721a8.51 8.51 0 110-11.986 8.519 8.519 0 010 11.986zM53.96 237.921a2.113 2.113 0 012.18 2.114v8.489a2.18 2.18 0 01-2.18 2.125h-2.845v3.388l-3.389-3.388H37.005a2.18 2.18 0 01-2.18-2.125v-8.489a2.114 2.114 0 012.18-2.114H53.96zm0-2.648H37.003a4.775 4.775 0 00-4.773 4.762v8.489a4.773 4.773 0 004.773 4.762h9.633l2.604 2.615a2.623 2.623 0 001.874.784c.345.001.686-.069 1.003-.207a2.64 2.64 0 001.634-2.441v-.751h.207a4.776 4.776 0 004.773-4.762v-8.489a4.774 4.774 0 00-4.773-4.762zM264.101 219.179c-.155 0-.309-.022-.458-.066a1.43 1.43 0 001.428 1.417h.763v.261a1.09 1.09 0 001.089 1.09h3.411a1.09 1.09 0 001.09-1.09v-.272h.762a1.44 1.44 0 001.428-1.417 1.616 1.616 0 01-.458.066l-9.055.011zm-.458-1.232h9.938v-1.416h-9.938v1.416zm0-2.833h9.938v-1.416h-9.938v1.416zm5.361-9.338l-.392.239-1.885-1.089 1.885 6.396 1.885-6.396-1.493.85zm7.966-14.842a11.803 11.803 0 00-3.833-2.567 11.805 11.805 0 00-12.816 2.51 11.855 11.855 0 00-3.434 6.901c-.064.487-.097.979-.098 1.471a11.874 11.874 0 004.915 9.632 9.82 9.82 0 013.018 3.574h2.637l-3.269-10.896 4.468 2.68 4.467-2.68-3.203 10.896h2.648a10.349 10.349 0 013.105-3.65 11.898 11.898 0 004.806-9.524 11.754 11.754 0 00-3.411-8.347zM35.63 157.221H19.501v2.31H35.63v-2.31zm4.282-5.819H14.926a1.157 1.157 0 00-1.09 1.155v32.559a1.165 1.165 0 001.09 1.155H31.27a.724.724 0 00.218 0h.251l.13-.087h.087c.07-.057.132-.123.185-.196l8.62-10.962a1.29 1.29 0 00.174-.316v-.098a2.021 2.021 0 000-.251v-21.793a1.155 1.155 0 00-1.079-1.166h.055zm-7.41 30.391v-6.342h5.013l-5.013 6.342zm6.255-8.652H31.27a1.15 1.15 0 00-1.09 1.155v9.665H16.113v-30.249h22.59l.054 19.429zm-6.473-7.116H19.502v2.31H32.23l.054-2.31zm3.345-4.358H19.502v2.31H35.63v-2.31z"
			></path>
			<path fill="#BBE3FF" d="M207.461 16.838h-1.09v1.09h1.09v-1.09z"></path>
			<path
				fill="#A6BFF7"
				d="M182.932 123.528h-1.09v-2.179h1.09v2.179zm0-5.448h-1.09v-2.18h1.09v2.18zm0-5.449h-1.09v-2.179h1.09v2.179zm0-5.448h-1.09v-2.179h1.09v2.179zm0-5.448h-1.09v-2.18h1.09v2.18zm0-5.449h-1.09v-2.179h1.09v2.18zm0-5.448h-1.09v-2.18h1.09v2.18zm0-5.448h-1.09v-2.18h1.09v2.18zm0-5.449h-1.09v-2.179h1.09v2.18zm0-5.448h-1.09v-2.18h1.09v2.18zm0-5.448h-1.09v-2.18h1.09v2.18zm0-5.449h-1.09v-2.179h1.09v2.18zm0-5.448h-1.09v-2.18h1.09v2.18zm0-5.448h-1.09v-2.18h1.09v2.18zm0-5.449h-1.09v-2.179h1.09v2.18zm0-5.448h-1.09v-2.18h1.09v2.18zm0-5.448h-1.09v-2.18h1.09v2.18zm0-5.449h-1.09v-2.179h1.09v2.18zm.403-5.263l-1.09-.294c.205-.753.486-1.483.839-2.18l.97.491a10.45 10.45 0 00-.719 1.962v.021zm2.561-4.445l-.785-.752a11.958 11.958 0 011.744-1.482l.621.893a10.9 10.9 0 00-1.58 1.319v.022zm4.358-2.725l-.337-1.09c.708-.235 1.438-.4 2.179-.49l.131 1.09c-.675.093-1.339.25-1.984.468l.011.022zm12.858-.523h-2.179v-1.09h2.179v1.09zm-5.448 0h-2.179v-1.09h2.179v1.09z"
			></path>
			<path
				fill="#BBE3FF"
				d="M182.932 126.808h-1.09v1.09h1.09v-1.09zM27.534 247.151h-1.09v1.089h1.09v-1.089z"
			></path>
			<path
				fill="#A6BFF7"
				d="M23.243 248.24h-2.18v-1.089h2.18v1.089zm-5.362 0h-2.179v-1.089h2.18v1.089zm-5.35 0h-2.18v-1.089h2.18v1.089zm-5.448-.196a8.95 8.95 0 01-2.18-.752l.49-.969a8.302 8.302 0 001.908.621l-.218 1.1zm-4.838-2.811a8.83 8.83 0 01-1.286-1.874l.97-.502c.294.592.66 1.144 1.09 1.646l-.774.73zM0 240.09v-2.19h1.09v2.092L0 240.09zm1.09-5.449H0v-2.179h1.09v2.179zm0-5.35H0v-2.179h1.09v2.179zm0-5.361H0v-2.179h1.09v2.179zm0-5.35H0v-2.18h1.09v2.18zm0-5.351H0v-2.179h1.09v2.179zm0-5.361H0v-2.179h1.09v2.179zm0-5.35H0v-2.179h1.09v2.179zm0-5.35H0v-2.18h1.09v2.18zm0-5.361H0v-2.18h1.09v2.18zm0-5.351H0v-2.179h1.09v2.179zm0-5.361H0v-2.179h1.09v2.179zm.468-5.11l-1.035-.371a9.266 9.266 0 011.035-2.016l.905.611a7.63 7.63 0 00-.948 1.819l.043-.043zm3.019-3.88l-.6-.904a8.652 8.652 0 012.027-1.024l.36 1.024a7.852 7.852 0 00-1.831.948l.044-.044z"
			></path>
			<path fill="#BBE3FF" d="M10.416 169.708h-1.09v1.089h1.09v-1.089z"></path>
			<path
				fill="#A6BFF7"
				d="M50.428 225.76a2.52 2.52 0 01-3.002 2.481 2.515 2.515 0 01-1.842-3.43 2.516 2.516 0 012.327-1.557 2.502 2.502 0 012.325 1.545c.127.305.192.631.192.961zM57.883 225.76a2.52 2.52 0 01-1.546 2.334 2.518 2.518 0 11-.009-4.652 2.498 2.498 0 011.555 2.318zM65.346 225.76a2.518 2.518 0 11-5.036.022 2.518 2.518 0 015.036-.022zM150.426 104.906a3.855 3.855 0 01-3.783-4.61 3.855 3.855 0 015.259-2.811 3.859 3.859 0 011.252 6.291 3.857 3.857 0 01-2.728 1.13zm0-6.614a2.769 2.769 0 00-1.953 4.728c.388.387.882.65 1.42.755a2.768 2.768 0 10.533-5.483zM150.426 95.099a3.857 3.857 0 110-7.713 3.857 3.857 0 010 7.713zm0-6.614a2.77 2.77 0 00-2.714 3.311 2.772 2.772 0 002.181 2.172 2.77 2.77 0 001.591-5.277 2.753 2.753 0 00-1.058-.206zM150.426 85.292a3.857 3.857 0 110-7.713 3.857 3.857 0 010 7.713zm0-6.615a2.77 2.77 0 00-2.714 3.312 2.772 2.772 0 002.181 2.172 2.769 2.769 0 003.09-3.784 2.754 2.754 0 00-2.557-1.7zM262.847 235.001h-3.945v3.944h3.945v-3.944zM262.847 242.901h-3.945v3.945h3.945v-3.945zM262.847 250.801h-3.945v3.945h3.945v-3.945zM398.489 119.409v3.944h3.945v-3.944h-3.945zM406.39 119.409v3.944h3.944v-3.944h-3.944zM414.29 119.398v3.945h3.945v-3.945h-3.945z"
			></path>
			<path fill="#BBE3FF" d="M379.006 27.823h-1.09v1.09h1.09v-1.09z"></path>
			<path
				fill="#A6BFF7"
				d="M401.007 109.984h-1.09v-2.18h1.09v2.18zm0-5.569h-1.09v-2.179h1.09v2.179zm0-5.579h-1.09V96.57h1.09v2.266zm0-5.579h-1.09v-2.234h1.09v2.234zm0-5.579h-1.09v-2.234h1.09v2.234zm0-5.579h-1.09v-2.18h1.09v2.18zm0-5.568h-1.09v-2.234h1.09v2.234zm0-5.58h-1.09v-2.233h1.09v2.234zm0-5.578h-1.09v-2.234h1.09v2.234zm0-5.58h-1.09v-2.179h1.09v2.18zm0-5.579h-1.09v-2.179h1.09v2.18zm0-5.568h-1.09v-2.18h1.09v2.18zm0-5.579h-1.09v-2.234h1.09v2.234zm0-5.579h-1.09v-2.18h1.09v2.18zm-1.624-5.209a6.25 6.25 0 00-1.166-1.656l.763-.774a6.875 6.875 0 011.384 1.973l-.981.457zm-3.803-3.138a6.053 6.053 0 00-1.405-.174h-.676v-1.09h.632a7.222 7.222 0 011.678.207l-.229 1.057zm-5.448-.174h-2.179v-1.09h2.179v1.09zm-5.579 0h-2.179v-1.09h2.179v1.09z"
			></path>
			<path
				fill="#BBE3FF"
				d="M401.007 113.35h-1.089v1.09h1.089v-1.09zM416.806 126.382h-1.09v1.09h1.09v-1.09z"
			></path>
			<path
				fill="#A6BFF7"
				d="M415.201 257.143c-2.281 0-4-4.013-4-9.329 0-5.315 1.719-9.324 4-9.324 2.28 0 3.994 4.009 3.994 9.324 0 5.316-1.719 9.329-3.994 9.329zm0-17.555c-1.383 0-2.92 3.384-2.92 8.231 0 4.848 1.537 8.232 2.92 8.232 1.382 0 2.914-3.379 2.914-8.232 0-4.852-1.536-8.236-2.914-8.236v.005z"
			></path>
			<path
				fill="#A6BFF7"
				d="M421.303 253.146a10.378 10.378 0 01-1.234-.079 20.409 20.409 0 01-6.311-2.067c-4.773-2.407-7.469-5.591-6.27-7.406.612-.923 2.093-1.285 4.172-1.024a20.149 20.149 0 016.311 2.067c4.773 2.406 7.468 5.59 6.27 7.401-.485.729-1.507 1.108-2.938 1.108zm-10.882-9.666c-.981 0-1.684.212-1.947.609-.728 1.1 1.517 3.855 5.856 6.054a18.951 18.951 0 005.901 1.943c1.542.195 2.675 0 3.034-.543.728-1.099-1.517-3.855-5.856-6.05a18.891 18.891 0 00-5.901-1.943 8.576 8.576 0 00-1.087-.07z"
			></path>
			<path
				fill="#A6BFF7"
				d="M410.425 253.146c-1.431 0-2.453-.379-2.938-1.108-1.199-1.811 1.517-4.995 6.271-7.401a20.146 20.146 0 016.312-2.067c2.078-.261 3.54.101 4.172 1.024 1.199 1.815-1.517 4.999-6.271 7.406a20.414 20.414 0 01-6.312 2.067c-.409.051-.821.077-1.234.079zm3.904-7.653c-4.359 2.208-6.574 4.951-5.856 6.05.349.535 1.482.738 3.034.543a18.957 18.957 0 005.902-1.943c4.36-2.208 6.575-4.954 5.857-6.054-.349-.53-1.482-.733-3.034-.539a18.894 18.894 0 00-5.903 1.943z"
			></path>
			<path
				fill="#A6BFF7"
				d="M415.869 250.481c-.395.002-.782-.154-1.112-.446-.329-.292-.586-.707-.738-1.194a3.49 3.49 0 01-.115-1.54c.076-.518.266-.993.546-1.366.279-.374.635-.628 1.023-.731.388-.104.79-.051 1.155.15.366.202.678.543.898.982.22.438.337.953.337 1.481 0 .705-.21 1.382-.584 1.882-.374.499-.881.781-1.41.782zm0-3.931a.782.782 0 00-.518.209 1.243 1.243 0 00-.343.558c-.07.227-.089.476-.053.717s.125.463.255.636a.896.896 0 00.477.34.72.72 0 00.539-.07c.17-.094.315-.254.418-.458.102-.204.157-.444.157-.69a1.507 1.507 0 00-.28-.87c-.173-.231-.407-.364-.652-.372z"
			></path>
			<path
				fill="#C3D3DD"
				d="M400.004 377.638l-65.38-31.361a1.544 1.544 0 01-.889-1.4 1.552 1.552 0 01.889-1.4l62.459-29.966-62.459-29.966a1.526 1.526 0 01-.883-1.394 1.552 1.552 0 01.883-1.395l62.459-29.955-62.459-30.01a1.546 1.546 0 01-.646-2.221c.156-.248.38-.445.646-.568l65.38-31.361 1.329 2.79-62.459 29.966 62.459 29.955a1.544 1.544 0 01.889 1.4 1.55 1.55 0 01-.889 1.4l-62.459 29.955 62.459 29.966a1.541 1.541 0 01.883 1.395 1.526 1.526 0 01-.883 1.394l-62.459 29.977 62.459 29.966-1.329 2.833z"
			></path>
			<path
				fill="#DCE8EF"
				d="M335.953 377.638l-1.329-2.789 62.459-29.966-62.459-29.977a1.521 1.521 0 01-.883-1.395 1.546 1.546 0 01.883-1.394l62.459-29.966-62.459-29.955a1.544 1.544 0 01-.889-1.4 1.553 1.553 0 01.889-1.401l62.459-29.955-62.459-29.965 1.329-2.79 65.38 31.361a1.526 1.526 0 01.883 1.394 1.55 1.55 0 01-.883 1.395l-62.459 29.966 62.459 29.922a1.548 1.548 0 01.646 2.222c-.156.247-.38.444-.646.568l-62.459 29.966 62.459 29.965a1.544 1.544 0 01.889 1.401 1.552 1.552 0 01-.889 1.4l-65.38 31.393z"
			></path>
			<path
				fill="#C1C7D0"
				d="M411.097 174.688h-82.368c-.975 0-1.765.79-1.765 1.765v12.444c0 .975.79 1.765 1.765 1.765h82.368c.975 0 1.765-.79 1.765-1.765v-12.444c0-.975-.79-1.765-1.765-1.765zM411.097 375.48h-82.368c-.975 0-1.765.791-1.765 1.765v12.444c0 .975.79 1.766 1.765 1.766h82.368c.975 0 1.765-.791 1.765-1.766v-12.444c0-.974-.79-1.765-1.765-1.765z"
			></path>
			<path
				fill="#A6BFF7"
				d="M416.806 230.654h-1.09v-2.18h1.09v2.18zm0-5.449h-1.09v-2.179h1.09v2.179zm0-5.448h-1.09v-2.179h1.09v2.179zm0-5.448h-1.09v-2.18h1.09v2.18zm0-5.449h-1.09v-2.179h1.09v2.179zm0-5.448h-1.09v-2.179h1.09v2.179zm0-5.448h-1.09v-2.18h1.09v2.18zm0-5.449h-1.09v-2.179h1.09v2.179zm0-5.448h-1.09v-2.179h1.09v2.179zm0-5.448h-1.09v-2.18h1.09v2.18zm0-5.449h-1.09v-2.179h1.09v2.179zm0-5.448h-1.09v-2.179h1.09v2.179zm0-5.448h-1.09v-2.18h1.09v2.18zm0-5.449h-1.09v-2.179h1.09v2.179zm0-5.448h-1.09v-2.179h1.09v2.179zm0-5.448h-1.09v-2.18h1.09v2.18zm0-5.449h-1.09v-2.179h1.09v2.179zm0-5.448h-1.09v-2.179h1.09v2.179zm0-5.448h-1.09v-2.18h1.09v2.18z"
			></path>
			<path
				fill="#BBE3FF"
				d="M416.806 233.911h-1.09v1.09h1.09v-1.09zM144.052 200.262h-1.09v1.09h1.09v-1.09z"
			></path>
			<path
				fill="#A6BFF7"
				d="M213.387 245.854h-2.179v-1.09h2.179v1.09zm-5.59 0h-2.179v-1.09h2.179v1.09zm-5.448 0h-2.179v-1.09h2.179v1.09zm-5.536 0h-2.179v-1.09h2.179v1.09zm-5.546 0h-2.179v-1.09h2.179v1.09zm-5.546 0h-2.18v-1.09h2.18v1.09zm-5.547 0h-2.179v-1.09h2.179v1.09zm-5.535 0h-2.18v-1.09h2.18v1.09zm-5.547 0h-2.179v-1.09h2.179v1.09zm-5.546 0h-2.179v-1.09h2.179v1.09zm-5.536 0h-2.179v-1.09h2.179v1.09zm-5.546 0h-2.179v-1.09h2.179v1.09zm-5.655-.937a7.733 7.733 0 01-1.875-1.482l.807-.73c.464.498 1 .924 1.591 1.264l-.523.948zm-3.564-4.609a7.47 7.47 0 01-.228-1.853v-.479h1.089v.479c0 .533.066 1.064.196 1.58l-1.057.273zm.861-5.667h-1.089v-2.179h1.089v2.179zm0-5.535h-1.089v-2.18h1.089v2.18zm0-5.547h-1.089v-2.179h1.089v2.179zm0-5.546h-1.089v-2.179h1.089v2.179zm0-5.536h-1.089v-2.179h1.089v2.179zm0-5.546h-1.089v-2.179h1.089v2.179z"
			></path>
			<path
				fill="#BBE3FF"
				d="M217.823 244.732h-1.09v1.089h1.09v-1.089zM157.706 80.89h-1.09v1.09h1.09v-1.09z"
			></path>
			<path
				fill="#A6BFF7"
				d="M175.905 81.98h-2.43v-1.09h2.43v1.09zm-6.07 0h-2.419v-1.09h2.419v1.09zm-6.058 0h-2.43v-1.09h2.43v1.09z"
			></path>
			<path
				fill="#BBE3FF"
				d="M180.631 80.89h-1.089v1.09h1.089v-1.09zM1.59 223.821H.5v1.09h1.09v-1.09z"
			></path>
			<path
				fill="#A6BFF7"
				d="M10.938 224.91h-.697v-1.089h.697a5.95 5.95 0 001.33-.153l.24 1.09a7.517 7.517 0 01-1.57.152zm-3.945 0H4.814v-1.089h2.18v1.089zm8.62-1.743l-.708-.817a6.23 6.23 0 001.264-1.559l.937.545a6.899 6.899 0 01-1.493 1.831zm2.484-5.111h-1.09v-2.179h1.09v2.179zm0-5.448h-1.09v-2.179h1.09v2.179zm.6-5.067l-.981-.479a6.815 6.815 0 011.362-1.896l.763.773a5.915 5.915 0 00-1.145 1.613v-.011zm3.683-3.095l-.295-1.089a7.274 7.274 0 011.886-.251h.403v1.09h-.403c-.54.006-1.076.09-1.591.25zm39.838-.217h-2.18v-1.09h2.18v1.09zm-5.449 0H54.59v-1.09h2.18v1.09zm-5.448 0h-2.18v-1.09h2.18v1.09zm-5.448 0h-2.18v-1.09h2.18v1.09zm-5.449 0h-2.179v-1.09h2.18v1.09zm-5.448 0h-2.18v-1.09h2.18v1.09zm-5.448 0h-2.18v-1.09h2.18v1.09z"
			></path>
			<path fill="#BBE3FF" d="M66.588 203.172h-1.09v1.09h1.09v-1.09z"></path>
			<path
				fill="#A6BFF7"
				d="M55.464 49.954h35.948v-3.27H55.464M55.464 57.102h35.948v-3.269H55.464M39.27 51.774l4.305 4.696 7.126-8.477-2.506-2.104-4.73 5.634-1.786-1.961-2.409 2.212zM55.464 64.26h35.948v-3.268H55.464M55.464 71.41h21.412v-3.27H55.464M141.404 91.786h-2.179v-1.09h2.179v1.09zm-5.448 0h-2.18v-1.09h2.18v1.09zm-5.449 0h-2.179v-1.09h2.179v1.09zm-5.448 0h-2.179v-1.09h2.179v1.09zm-5.448 0h-2.18v-1.09h2.18v1.09zm-5.449 0h-2.179v-1.09h2.179v1.09zm-5.448 0h-2.179v-1.09h2.179v1.09zm-5.448 0h-2.18v-1.09h2.18v1.09zm-5.449 0h-2.179v-1.09h2.18v1.09zm-5.448 0h-2.18v-1.09h2.18v1.09zm-5.448 0h-2.18v-1.09h2.18v1.09zm-5.449 0h-1.678a4.46 4.46 0 01-.544 0l.087-1.09h2.179l-.044 1.09zm-5.524-1.177a6.877 6.877 0 01-1.7-1.624l.883-.653c.389.54.872 1.005 1.427 1.372l-.61.905zm-3.008-4.936a6.935 6.935 0 010-.829v-1.35h1.09v1.405a4.803 4.803 0 000 .697l-1.09.077zm1.09-5.449h-1.09v-2.08h1.09v2.08z"
			></path>
			<defs>
				<linearGradient
					id="paint0_linear_8413_44840"
					x1="82.626"
					x2="104.92"
					y1="328.498"
					y2="261.364"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#F0F5F7"></stop>
					<stop offset="1" stopColor="#fff"></stop>
				</linearGradient>
			</defs>
		</svg>
	)
}
