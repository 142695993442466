import React from "react"
import { Illustration } from "types/components"
import { mergeClasses } from "utils"

export const Pattern1Illustration: Illustration = ({
	className = "",
	...props
}) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 93 89"
			className={mergeClasses({ classes: ["w-10", className] })}
			{...props}
		>
			<circle
				cx="46.596"
				cy="72.436"
				r="16.463"
				fill="url(#paint0_linear_6592_41826)"
			></circle>
			<circle
				cx="10.378"
				cy="46.096"
				r="9.878"
				fill="url(#paint1_linear_6592_41826)"
				transform="rotate(-90 10.378 46.096)"
			></circle>
			<path
				stroke="url(#paint2_linear_6592_41826)"
				strokeWidth="4"
				d="M75.428 53.847l-14.98-9.87V28.728l14.98-9.87 14.98 9.87v15.25l-14.98 9.87z"
			></path>
			<path
				fill="url(#paint3_linear_6592_41826)"
				d="M51.864 23.048H74.91199999999999V46.096H51.864z"
				transform="rotate(180 51.864 23.048)"
			></path>
			<path
				stroke="url(#paint4_linear_6592_41826)"
				strokeWidth="3"
				d="M46.266 56.302v-9.548m0 0h-26.34m26.34 0v-8.56m0 0h12.841m-12.84 0v-4.915a2 2 0 00-2-2h-1.598a2 2 0 01-2-2v-6.56"
			></path>
			<defs>
				<linearGradient
					id="paint0_linear_6592_41826"
					x1="53.877"
					x2="35.515"
					y1="68.953"
					y2="81.934"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#F39244"></stop>
					<stop offset="1" stopColor="#F8CCA9"></stop>
				</linearGradient>
				<linearGradient
					id="paint1_linear_6592_41826"
					x1="5.933"
					x2="19.514"
					y1="54.492"
					y2="40.416"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#3C67F0"></stop>
					<stop offset="1" stopColor="#CCDBFB" stopOpacity="0"></stop>
				</linearGradient>
				<linearGradient
					id="paint2_linear_6592_41826"
					x1="67.666"
					x2="89.982"
					y1="40.719"
					y2="25.195"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#7BC598"></stop>
					<stop offset="1" stopColor="#9CF1BE" stopOpacity="0"></stop>
				</linearGradient>
				<linearGradient
					id="paint3_linear_6592_41826"
					x1="63.388"
					x2="63.388"
					y1="23.048"
					y2="46.096"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#1753E2"></stop>
					<stop offset="1" stopColor="#CCDBFB" stopOpacity="0"></stop>
				</linearGradient>
				<linearGradient
					id="paint4_linear_6592_41826"
					x1="33.755"
					x2="33.096"
					y1="20.414"
					y2="56.302"
					gradientUnits="userSpaceOnUse"
				>
					<stop offset="0.108" stopColor="#225BE3"></stop>
					<stop offset="0.298" stopColor="#7BC598"></stop>
					<stop offset="0.515" stopColor="#7BC598"></stop>
					<stop offset="0.663" stopColor="#6688F3"></stop>
					<stop offset="0.83" stopColor="#6688F3"></stop>
					<stop offset="1" stopColor="#F6AD73"></stop>
				</linearGradient>
			</defs>
		</svg>
	)
}
