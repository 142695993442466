import React from "react"
import { Illustration } from "types/components"
import { mergeClasses } from "utils"

export const Pattern10Illustration: Illustration = ({
	className = "",
	...props
}) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 61 61"
			className={mergeClasses({ classes: ["w-20", className] })}
			{...props}
		>
			<circle
				cx="30.055"
				cy="30.055"
				r="27.555"
				stroke="url(#paint0_linear_5619_38348)"
				strokeWidth="5"
			></circle>
			<path
				stroke="url(#paint1_linear_5619_38348)"
				strokeWidth="5"
				d="M57.61 30.445c0 .506-.275 1.312-1.492 2.358-1.21 1.04-3.105 2.09-5.666 3.02-5.1 1.855-12.31 3.051-20.397 3.051-8.086 0-15.298-1.196-20.398-3.05-2.56-.931-4.456-1.98-5.665-3.02C2.774 31.756 2.5 30.95 2.5 30.444s.274-1.312 1.492-2.358c1.209-1.04 3.105-2.09 5.665-3.02 5.1-1.855 12.312-3.05 20.398-3.05s15.297 1.195 20.398 3.05c2.56.93 4.456 1.98 5.665 3.02 1.217 1.046 1.492 1.852 1.492 2.358z"
			></path>
			<path
				stroke="url(#paint2_linear_5619_38348)"
				strokeWidth="5"
				d="M30.055 58c-.506 0-1.312-.274-2.358-1.492-1.04-1.209-2.09-3.105-3.02-5.665-1.855-5.1-3.051-12.312-3.051-20.398s1.196-15.297 3.05-20.398c.931-2.56 1.98-4.456 3.02-5.665 1.047-1.217 1.853-1.492 2.359-1.492s1.312.275 2.358 1.492c1.04 1.21 2.09 3.105 3.02 5.665 1.855 5.1 3.05 12.312 3.05 20.398s-1.195 15.298-3.05 20.398c-.93 2.56-1.98 4.456-3.02 5.665C31.367 57.726 30.561 58 30.055 58z"
			></path>
			<defs>
				<linearGradient
					id="paint0_linear_5619_38348"
					x1="30.055"
					x2="30.055"
					y1="0"
					y2="60.11"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#3C67F0"></stop>
					<stop offset="1" stopColor="#DEE9FD" stopOpacity="0"></stop>
				</linearGradient>
				<linearGradient
					id="paint1_linear_5619_38348"
					x1="30.055"
					x2="30.055"
					y1="19.516"
					y2="41.374"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#F39244"></stop>
					<stop offset="1" stopColor="#F8CCA9" stopOpacity="0"></stop>
				</linearGradient>
				<linearGradient
					id="paint2_linear_5619_38348"
					x1="34.348"
					x2="30.445"
					y1="9.368"
					y2="59.719"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#7BC598"></stop>
					<stop offset="1" stopColor="#9CF1BE" stopOpacity="0"></stop>
				</linearGradient>
			</defs>
		</svg>
	)
}
