import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { Illustration } from "types/components"
import { TranslationKey } from "types/translation"

type P = {
	title: TranslationKey
	desc: TranslationKey
	icon: Illustration
}

export type PurposeCardProps = P

export const PurposeCard = ({ desc, icon, title }: P) => {
	const { t } = useTranslation()
	const Icon = icon

	return (
		<div className="bg-white shadow-md border-t-theme-blue border-t-[20px] rounded-md flex flex-col gap-4 p-6">
			<Icon className="w-16" />
			<p className="text-2xl">{t(title) as string}</p>
			<p className="text-sm">{t(desc) as string}</p>
		</div>
	)
}
