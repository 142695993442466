import React from "react"
import { Illustration } from "types/components"
import { mergeClasses } from "utils"

export const Pattern2Illustration: Illustration = ({
	className = "",
	...props
}) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 120 120"
			className={mergeClasses({ classes: ["w-20", className] })}
			{...props}
		>
			<circle cx="60" cy="60" r="60" fill="#DEE9FD"></circle>
			<path
				fill="url(#paint0_linear_19_105)"
				d="M16.69 61.522H64.401V73.727H16.69z"
				transform="rotate(-45 16.69 61.522)"
			></path>
			<path
				fill="url(#paint1_linear_19_105)"
				d="M26.105 70.937H38.31V98.942H26.105z"
				transform="rotate(-45 26.105 70.937)"
			></path>
			<path
				fill="url(#paint2_linear_19_105)"
				d="M104 59.168H151.711V71.373H104z"
				transform="rotate(135 104 59.168)"
			></path>
			<path
				fill="url(#paint3_linear_19_105)"
				d="M94.585 49.753H106.78999999999999V78.866H94.585z"
				transform="rotate(135 94.585 49.753)"
			></path>
			<path
				fill="#5F85EB"
				d="M56.32 57.478l3.17-12.682c.063-.252.422-.252.485 0l3.17 12.682a.25.25 0 00.206.187L75.586 59.5c.277.041.287.437.012.492l-12.257 2.452a.25.25 0 00-.193.184l-3.173 12.69c-.063.253-.422.253-.485 0l-3.172-12.69a.25.25 0 00-.194-.184l-12.257-2.452c-.274-.055-.265-.45.012-.492l12.235-1.835a.25.25 0 00.206-.187z"
			></path>
			<path
				fill="#1B55CE"
				d="M63.186 62.475L60.09 74.234c-.046.174-.303.135-.295-.045l.575-12.646a1.25 1.25 0 011.192-1.192l12.33-.56c.183-.009.218.256.039.296l-10.746 2.388z"
			></path>
			<defs>
				<linearGradient
					id="paint0_linear_19_105"
					x1="17.799"
					x2="66.62"
					y1="73.727"
					y2="63.186"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#1753E2"></stop>
					<stop offset="1" stopColor="#DEE9FD"></stop>
				</linearGradient>
				<linearGradient
					id="paint1_linear_19_105"
					x1="32.207"
					x2="32.207"
					y1="70.937"
					y2="98.941"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#7BC598"></stop>
					<stop offset="1" stopColor="#9CF1BE"></stop>
				</linearGradient>
				<linearGradient
					id="paint2_linear_19_105"
					x1="105.109"
					x2="153.93"
					y1="71.373"
					y2="60.833"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#F39244"></stop>
					<stop offset="1" stopColor="#F8CCA9"></stop>
				</linearGradient>
				<linearGradient
					id="paint3_linear_19_105"
					x1="100.687"
					x2="100.687"
					y1="49.753"
					y2="78.866"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#7BC598"></stop>
					<stop offset="1" stopColor="#9CF1BE"></stop>
				</linearGradient>
			</defs>
		</svg>
	)
}
