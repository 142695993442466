import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { PurposeCard, PurposeCardProps } from "./purpose-card"
import { Pattern10Illustration } from "components/library/illustrations/patterns/pattern10";
import { Pattern9Illustration } from "components/library/illustrations/patterns/pattern9";
import { Pattern1Illustration } from "components/library/illustrations/patterns/pattern1";
import { Pattern11Illustration } from "components/library/illustrations/patterns/pattern11";
import { Pattern2Illustration } from "components/library/illustrations/patterns/pattern2";

const cards: PurposeCardProps[] = [
	{
		title: "products-doe-page.purpose.cards.1.title",
		desc: "products-doe-page.purpose.cards.1.desc",
		icon: Pattern10Illustration,
	},
	{
		title: "products-doe-page.purpose.cards.2.title",
		desc: "products-doe-page.purpose.cards.2.desc",
		icon: Pattern9Illustration,
	},
	{
		title: "products-doe-page.purpose.cards.3.title",
		desc: "products-doe-page.purpose.cards.3.desc",
		icon: Pattern1Illustration,
	},
	{
		title: "products-doe-page.purpose.cards.4.title",
		desc: "products-doe-page.purpose.cards.4.desc",
		icon: Pattern11Illustration,
	},
]

export const Purpose = () => {
	const { t } = useTranslation()

	return (
		<section id="purpose" className="flex flex-col gap-12 items-center">
			<div className="flex flex-col gap-4 items-center">
				<Pattern2Illustration className="w-20 sm:w-32" />
				<p className="gradient-text uppercase">
					{t("products-doe-page.purpose.label")}
				</p>
			</div>

			<h2 className="text-2xl sm:text-3xl lg:text-4xl text-center">
				{t("products-doe-page.purpose.title")}
			</h2>

			<div className="grid grid-col-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-10">
				{cards.map((card) => {
					return (
						<PurposeCard
							desc={card.desc}
							icon={card.icon}
							title={card.title}
							key={card.title}
						/>
					)
				})}
			</div>
		</section>
	)
}
