import React from "react"
import { Illustration } from "types/components"
import { mergeClasses } from "utils"

export const Pattern12Illustration: Illustration = ({
	className = "",
	...props
}) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 64 78"
			className={mergeClasses({ classes: ["w-20", className] })}
			{...props}
		>
			<path
				fill="url(#paint0_linear_7120_41735)"
				d="M0 25.133H35.2V60.333H0z"
			></path>
			<path
				stroke="url(#paint1_linear_7120_41735)"
				strokeWidth="4"
				d="M22.662 52.333l10.982-16.666h16.979l10.982 16.666L50.623 69H33.644L22.662 52.333z"
			></path>
			<path
				fill="url(#paint2_linear_7120_41735)"
				d="M48 0.6H58.667V77.39999999999999H48z"
			></path>
			<circle
				cx="35.733"
				cy="18.2"
				r="17.6"
				fill="url(#paint3_linear_7120_41735)"
			></circle>
			<defs>
				<linearGradient
					id="paint0_linear_7120_41735"
					x1="17.6"
					x2="17.6"
					y1="25.133"
					y2="60.333"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#1753E2"></stop>
					<stop offset="1" stopColor="#CCDBFB" stopOpacity="0"></stop>
				</linearGradient>
				<linearGradient
					id="paint1_linear_7120_41735"
					x1="37.333"
					x2="54.4"
					y1="43.8"
					y2="68.333"
					gradientUnits="userSpaceOnUse"
				>
					<stop offset="0.129" stopColor="#F39244"></stop>
					<stop offset="1" stopColor="#FAD8BD" stopOpacity="0"></stop>
				</linearGradient>
				<linearGradient
					id="paint2_linear_7120_41735"
					x1="53.333"
					x2="53.333"
					y1="0.6"
					y2="77.4"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#7BC598"></stop>
					<stop offset="1" stopColor="#7BC598" stopOpacity="0"></stop>
				</linearGradient>
				<linearGradient
					id="paint3_linear_7120_41735"
					x1="19.2"
					x2="51.733"
					y1="12.867"
					y2="24.6"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#7BC598"></stop>
					<stop offset="1" stopColor="#7BC598" stopOpacity="0"></stop>
				</linearGradient>
			</defs>
		</svg>
	)
}
