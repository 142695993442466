import React from "react"
import { Illustration } from "types/components"
import { mergeClasses } from "utils"

export const DoeIllustration: Illustration = ({ className = "", ...props }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 479 397"
			className={mergeClasses({ classes: ["w-40", className] })}
			{...props}
		>
			<rect
				width="125.384"
				height="76.383"
				y="129.708"
				fill="#F3F6FA"
				rx="4"
			></rect>
			<path stroke="#F4A362" d="M269.505 90.796l18.652-51.884h142.762"></path>
			<path stroke="#5F85EB" d="M177.268 242.121l-38.913-69.177H98.002"></path>
			<path stroke="#7BC598" d="M189.667 313.597l-28.327 45.261H65.737"></path>
			<path stroke="#B6CBD5" d="M249.327 307.696l28.103 28.103h105.928"></path>
			<rect
				width="112.413"
				height="64.854"
				x="7.205"
				y="135.473"
				fill="#fff"
				rx="4"
			></rect>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M11.126 188.953H18.381V196.208H11.126z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M11.126 158.796H18.381V166.051H11.126z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M11.126 173.874H18.381V181.129H11.126z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M11.126 143.717H18.381V150.972H11.126z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M26.206 188.953H33.461V196.208H26.206z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M26.206 158.796H33.461V166.051H26.206z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M26.206 173.874H33.461V181.129H26.206z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M26.206 143.717H33.461V150.972H26.206z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M18.665 188.953H25.919999999999998V196.208H18.665z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M18.665 158.796H25.919999999999998V166.051H18.665z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M18.665 173.874H25.919999999999998V181.129H18.665z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M18.665 143.717H25.919999999999998V150.972H18.665z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M33.745 188.953H41V196.208H33.745z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M33.745 158.796H41V166.051H33.745z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M33.745 173.874H41V181.129H33.745z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M33.745 143.717H41V150.972H33.745z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M48.822 188.953H56.077000000000005V196.208H48.822z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M48.822 158.796H56.077000000000005V166.051H48.822z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M48.822 173.874H56.077000000000005V181.129H48.822z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M26.206 181.413H33.461V188.668H26.206z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M26.206 151.256H33.461V158.511H26.206z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M26.206 166.335H33.461V173.59H26.206z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M18.665 181.413H25.919999999999998V188.668H18.665z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M18.665 151.256H25.919999999999998V158.511H18.665z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M18.665 166.335H25.919999999999998V173.59H18.665z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M33.745 181.413H41V188.668H33.745z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M33.745 151.256H41V158.511H33.745z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M33.745 166.335H41V173.59H33.745z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M48.822 143.717H56.077000000000005V150.972H48.822z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M41.286 188.953H48.541000000000004V196.208H41.286z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M41.286 158.796H48.541000000000004V166.051H41.286z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M41.286 173.874H48.541000000000004V181.129H41.286z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M41.286 143.717H48.541000000000004V150.972H41.286z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M56.361 188.953H63.616V196.208H56.361z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M56.361 158.796H63.616V166.051H56.361z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M56.361 173.874H63.616V181.129H56.361z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M56.361 143.717H63.616V150.972H56.361z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M11.126 181.413H18.381V188.668H11.126z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M11.126 151.256H18.381V158.511H11.126z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M11.126 166.335H18.381V173.59H11.126z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M48.822 181.413H56.077000000000005V188.668H48.822z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M48.822 151.256H56.077000000000005V158.511H48.822z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M48.822 166.335H56.077000000000005V173.59H48.822z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M41.286 181.413H48.541000000000004V188.668H41.286z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M41.286 151.256H48.541000000000004V158.511H41.286z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M41.286 166.335H48.541000000000004V173.59H41.286z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M56.361 181.413H63.616V188.668H56.361z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M56.361 151.256H63.616V158.511H56.361z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M56.361 166.335H63.616V173.59H56.361z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M63.975 188.953H71.23V196.208H63.975z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M63.975 158.796H71.23V166.051H63.975z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M63.975 173.874H71.23V181.129H63.975z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M63.975 143.717H71.23V150.972H63.975z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M79.057 188.953H86.312V196.208H79.057z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M79.057 158.796H86.312V166.051H79.057z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M79.057 173.874H86.312V181.129H79.057z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M79.057 143.717H86.312V150.972H79.057z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M71.516 188.953H78.771V196.208H71.516z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M71.516 158.796H78.771V166.051H71.516z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M71.516 173.874H78.771V181.129H71.516z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M71.516 143.717H78.771V150.972H71.516z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M86.596 188.953H93.851V196.208H86.596z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M86.596 158.796H93.851V166.051H86.596z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M86.596 173.874H93.851V181.129H86.596z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M86.596 143.717H93.851V150.972H86.596z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M101.671 188.953H108.926V196.208H101.671z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M101.671 158.796H108.926V166.051H101.671z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M101.671 173.874H108.926V181.129H101.671z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M79.057 181.413H86.312V188.668H79.057z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M79.057 151.256H86.312V158.511H79.057z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M79.057 166.335H86.312V173.59H79.057z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M71.516 181.413H78.771V188.668H71.516z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M71.516 151.256H78.771V158.511H71.516z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M71.516 166.335H78.771V173.59H71.516z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M86.596 181.413H93.851V188.668H86.596z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M86.596 151.256H93.851V158.511H86.596z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M86.596 166.335H93.851V173.59H86.596z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M101.671 143.717H108.926V150.972H101.671z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M94.137 188.953H101.392V196.208H94.137z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M94.137 158.796H101.392V166.051H94.137z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M94.137 173.874H101.392V181.129H94.137z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M94.137 143.717H101.392V150.972H94.137z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M109.212 188.953H116.467V196.208H109.212z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M109.212 158.796H116.467V166.051H109.212z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M109.212 173.874H116.467V181.129H109.212z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M109.212 143.717H116.467V150.972H109.212z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M63.975 181.413H71.23V188.668H63.975z"
				opacity="0.3"
			></path>
			<path
				stroke="#253858"
				d="M63.911 143.973v52.201M11.199 158.625H116.11"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M63.975 151.256H71.23V158.511H63.975z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M63.975 166.335H71.23V173.59H63.975z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M101.671 181.413H108.926V188.668H101.671z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M101.671 151.256H108.926V158.511H101.671z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M101.671 166.335H108.926V173.59H101.671z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M94.137 181.413H101.392V188.668H94.137z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M94.137 151.256H101.392V158.511H94.137z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M94.137 166.335H101.392V173.59H94.137z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M109.212 181.413H116.467V188.668H109.212z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M109.212 151.256H116.467V158.511H109.212z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M109.212 166.335H116.467V173.59H109.212z"
				opacity="0.3"
			></path>
			<path
				stroke="#000"
				strokeDasharray="0.5 1.5"
				strokeLinecap="round"
				strokeWidth="0.5"
				d="M18.54 150.919c0 38.982 16.771 39.435 89.296 41.249"
			></path>
			<circle cx="26.243" cy="166.784" r="0.907" fill="#253858"></circle>
			<circle cx="53.44" cy="192.167" r="0.907" fill="#253858"></circle>
			<circle cx="97.86" cy="192.167" r="0.907" fill="#253858"></circle>
			<circle cx="99.676" cy="188.541" r="0.907" fill="#253858"></circle>
			<circle cx="103.301" cy="192.167" r="0.907" fill="#253858"></circle>
			<circle cx="18.087" cy="176.756" r="0.907" fill="#253858"></circle>
			<circle cx="23.523" cy="172.223" r="0.907" fill="#253858"></circle>
			<circle cx="21.712" cy="179.476" r="0.907" fill="#253858"></circle>
			<circle cx="57.068" cy="189.448" r="0.907" fill="#253858"></circle>
			<circle
				cx="22.618"
				cy="187.634"
				r="0.657"
				stroke="#253858"
				strokeWidth="0.5"
			></circle>
			<circle
				cx="108.74"
				cy="193.074"
				r="0.657"
				stroke="#253858"
				strokeWidth="0.5"
			></circle>
			<circle
				cx="19.898"
				cy="193.074"
				r="0.657"
				stroke="#253858"
				strokeWidth="0.5"
			></circle>
			<circle
				cx="15.367"
				cy="150.466"
				r="0.657"
				stroke="#253858"
				strokeWidth="0.5"
			></circle>
			<circle
				cx="23.523"
				cy="168.597"
				r="0.657"
				stroke="#253858"
				strokeWidth="0.5"
			></circle>
			<rect
				width="125.384"
				height="76.383"
				y="319.946"
				fill="#F3F6FA"
				rx="4"
			></rect>
			<rect
				width="125.384"
				height="76.383"
				x="343.004"
				y="296.887"
				fill="#F3F6FA"
				rx="4"
			></rect>
			<rect
				width="125.384"
				height="76.383"
				x="353.094"
				fill="#F3F6FA"
				rx="4"
			></rect>
			<rect
				width="108.09"
				height="64.854"
				x="351.653"
				y="302.652"
				fill="#fff"
				rx="4"
			></rect>
			<rect
				width="108.09"
				height="64.854"
				x="363.182"
				y="5.765"
				fill="#fff"
				rx="4"
			></rect>
			<rect
				width="108.09"
				height="64.854"
				x="8.647"
				y="325.711"
				fill="#fff"
				rx="4"
			></rect>
			<g stroke="#C1C7D0" strokeWidth="0.5" opacity="0.5">
				<path
					d="M11.779 376.742H18.749V383.71200000000005H11.779z"
					opacity="0.3"
				></path>
				<path
					d="M11.779 347.692H18.749V354.66200000000003H11.779z"
					opacity="0.3"
				></path>
				<path d="M11.779 362.217H18.749V369.187H11.779z" opacity="0.3"></path>
				<path d="M11.779 333.167H18.749V340.137H11.779z" opacity="0.3"></path>
				<path
					d="M26.307 376.742H33.277V383.71200000000005H26.307z"
					opacity="0.3"
				></path>
				<path
					d="M26.307 347.692H33.277V354.66200000000003H26.307z"
					opacity="0.3"
				></path>
				<path d="M26.307 362.217H33.277V369.187H26.307z" opacity="0.3"></path>
				<path d="M26.307 333.167H33.277V340.137H26.307z" opacity="0.3"></path>
				<path
					d="M19.041 376.742H26.011V383.71200000000005H19.041z"
					opacity="0.3"
				></path>
				<path
					d="M19.041 347.692H26.011V354.66200000000003H19.041z"
					opacity="0.3"
				></path>
				<path d="M19.041 362.217H26.011V369.187H19.041z" opacity="0.3"></path>
				<path d="M19.041 333.167H26.011V340.137H19.041z" opacity="0.3"></path>
				<path
					d="M33.569 376.742H40.539V383.71200000000005H33.569z"
					opacity="0.3"
				></path>
				<path
					d="M33.569 347.692H40.539V354.66200000000003H33.569z"
					opacity="0.3"
				></path>
				<path d="M33.569 362.217H40.539V369.187H33.569z" opacity="0.3"></path>
				<path d="M33.569 333.167H40.539V340.137H33.569z" opacity="0.3"></path>
				<path
					d="M48.095 376.742H55.065V383.71200000000005H48.095z"
					opacity="0.3"
				></path>
				<path
					d="M48.095 347.692H55.065V354.66200000000003H48.095z"
					opacity="0.3"
				></path>
				<path d="M48.095 362.217H55.065V369.187H48.095z" opacity="0.3"></path>
				<path
					d="M26.307 369.48H33.277V376.45000000000005H26.307z"
					opacity="0.3"
				></path>
				<path d="M26.307 340.429H33.277V347.399H26.307z" opacity="0.3"></path>
				<path d="M26.307 354.955H33.277V361.925H26.307z" opacity="0.3"></path>
				<path
					d="M19.041 369.48H26.011V376.45000000000005H19.041z"
					opacity="0.3"
				></path>
				<path d="M19.041 340.429H26.011V347.399H19.041z" opacity="0.3"></path>
				<path d="M19.041 354.955H26.011V361.925H19.041z" opacity="0.3"></path>
				<path
					d="M33.569 369.48H40.539V376.45000000000005H33.569z"
					opacity="0.3"
				></path>
				<path d="M33.569 340.429H40.539V347.399H33.569z" opacity="0.3"></path>
				<path d="M33.569 354.955H40.539V361.925H33.569z" opacity="0.3"></path>
				<path d="M48.095 333.167H55.065V340.137H48.095z" opacity="0.3"></path>
				<path
					d="M40.833 376.742H47.803V383.71200000000005H40.833z"
					opacity="0.3"
				></path>
				<path
					d="M40.833 347.692H47.803V354.66200000000003H40.833z"
					opacity="0.3"
				></path>
				<path d="M40.833 362.217H47.803V369.187H40.833z" opacity="0.3"></path>
				<path d="M40.833 333.167H47.803V340.137H40.833z" opacity="0.3"></path>
				<path
					d="M55.355 376.742H62.324999999999996V383.71200000000005H55.355z"
					opacity="0.3"
				></path>
				<path
					d="M55.355 347.692H62.324999999999996V354.66200000000003H55.355z"
					opacity="0.3"
				></path>
				<path
					d="M55.355 362.217H62.324999999999996V369.187H55.355z"
					opacity="0.3"
				></path>
				<path
					d="M55.355 333.167H62.324999999999996V340.137H55.355z"
					opacity="0.3"
				></path>
				<path
					d="M11.779 369.48H18.749V376.45000000000005H11.779z"
					opacity="0.3"
				></path>
				<path d="M11.779 340.429H18.749V347.399H11.779z" opacity="0.3"></path>
				<path d="M11.779 354.955H18.749V361.925H11.779z" opacity="0.3"></path>
				<path
					d="M48.095 369.48H55.065V376.45000000000005H48.095z"
					opacity="0.3"
				></path>
				<path d="M48.095 340.429H55.065V347.399H48.095z" opacity="0.3"></path>
				<path d="M48.095 354.955H55.065V361.925H48.095z" opacity="0.3"></path>
				<path
					d="M40.833 369.48H47.803V376.45000000000005H40.833z"
					opacity="0.3"
				></path>
				<path d="M40.833 340.429H47.803V347.399H40.833z" opacity="0.3"></path>
				<path d="M40.833 354.955H47.803V361.925H40.833z" opacity="0.3"></path>
				<path
					d="M55.355 369.48H62.324999999999996V376.45000000000005H55.355z"
					opacity="0.3"
				></path>
				<path
					d="M55.355 340.429H62.324999999999996V347.399H55.355z"
					opacity="0.3"
				></path>
				<path
					d="M55.355 354.955H62.324999999999996V361.925H55.355z"
					opacity="0.3"
				></path>
			</g>
			<g stroke="#C1C7D0" strokeWidth="0.5" opacity="0.5">
				<path
					d="M62.693 376.742H69.663V383.71200000000005H62.693z"
					opacity="0.3"
				></path>
				<path
					d="M62.693 347.692H69.663V354.66200000000003H62.693z"
					opacity="0.3"
				></path>
				<path d="M62.693 362.217H69.663V369.187H62.693z" opacity="0.3"></path>
				<path d="M62.694 333.167H69.664V340.137H62.694z" opacity="0.3"></path>
				<path
					d="M77.221 376.742H84.191V383.71200000000005H77.221z"
					opacity="0.3"
				></path>
				<path
					d="M77.221 347.692H84.191V354.66200000000003H77.221z"
					opacity="0.3"
				></path>
				<path d="M77.221 362.217H84.191V369.187H77.221z" opacity="0.3"></path>
				<path d="M77.221 333.167H84.191V340.137H77.221z" opacity="0.3"></path>
				<path
					d="M69.955 376.742H76.925V383.71200000000005H69.955z"
					opacity="0.3"
				></path>
				<path
					d="M69.955 347.692H76.925V354.66200000000003H69.955z"
					opacity="0.3"
				></path>
				<path d="M69.955 362.217H76.925V369.187H69.955z" opacity="0.3"></path>
				<path d="M69.955 333.167H76.925V340.137H69.955z" opacity="0.3"></path>
				<path
					d="M84.481 376.742H91.451V383.71200000000005H84.481z"
					opacity="0.3"
				></path>
				<path
					d="M84.481 347.692H91.451V354.66200000000003H84.481z"
					opacity="0.3"
				></path>
				<path d="M84.481 362.217H91.451V369.187H84.481z" opacity="0.3"></path>
				<path d="M84.481 333.167H91.451V340.137H84.481z" opacity="0.3"></path>
				<path
					d="M99.007 376.742H105.977V383.71200000000005H99.007z"
					opacity="0.3"
				></path>
				<path
					d="M99.007 347.692H105.977V354.66200000000003H99.007z"
					opacity="0.3"
				></path>
				<path d="M99.007 362.217H105.977V369.187H99.007z" opacity="0.3"></path>
				<path
					d="M77.221 369.48H84.191V376.45000000000005H77.221z"
					opacity="0.3"
				></path>
				<path
					d="M77.221 340.43H84.191V347.40000000000003H77.221z"
					opacity="0.3"
				></path>
				<path d="M77.221 354.955H84.191V361.925H77.221z" opacity="0.3"></path>
				<path
					d="M69.955 369.48H76.925V376.45000000000005H69.955z"
					opacity="0.3"
				></path>
				<path
					d="M69.955 340.43H76.925V347.40000000000003H69.955z"
					opacity="0.3"
				></path>
				<path d="M69.955 354.955H76.925V361.925H69.955z" opacity="0.3"></path>
				<path
					d="M84.481 369.48H91.451V376.45000000000005H84.481z"
					opacity="0.3"
				></path>
				<path
					d="M84.481 340.43H91.451V347.40000000000003H84.481z"
					opacity="0.3"
				></path>
				<path d="M84.481 354.955H91.451V361.925H84.481z" opacity="0.3"></path>
				<path d="M99.007 333.167H105.977V340.137H99.007z" opacity="0.3"></path>
				<path
					d="M91.745 376.742H98.715V383.71200000000005H91.745z"
					opacity="0.3"
				></path>
				<path
					d="M91.745 347.692H98.715V354.66200000000003H91.745z"
					opacity="0.3"
				></path>
				<path d="M91.745 362.217H98.715V369.187H91.745z" opacity="0.3"></path>
				<path d="M91.745 333.167H98.715V340.137H91.745z" opacity="0.3"></path>
				<path
					d="M106.269 376.742H113.239V383.71200000000005H106.269z"
					opacity="0.3"
				></path>
				<path
					d="M106.269 347.692H113.239V354.66200000000003H106.269z"
					opacity="0.3"
				></path>
				<path
					d="M106.269 362.217H113.239V369.187H106.269z"
					opacity="0.3"
				></path>
				<path
					d="M106.269 333.167H113.239V340.137H106.269z"
					opacity="0.3"
				></path>
				<path
					d="M62.693 369.48H69.663V376.45000000000005H62.693z"
					opacity="0.3"
				></path>
				<path
					d="M62.693 340.43H69.663V347.40000000000003H62.693z"
					opacity="0.3"
				></path>
				<path d="M62.693 354.955H69.663V361.925H62.693z" opacity="0.3"></path>
				<path
					d="M99.007 369.48H105.977V376.45000000000005H99.007z"
					opacity="0.3"
				></path>
				<path
					d="M99.007 340.43H105.977V347.40000000000003H99.007z"
					opacity="0.3"
				></path>
				<path d="M99.007 354.955H105.977V361.925H99.007z" opacity="0.3"></path>
				<path
					d="M91.745 369.48H98.715V376.45000000000005H91.745z"
					opacity="0.3"
				></path>
				<path
					d="M91.745 340.43H98.715V347.40000000000003H91.745z"
					opacity="0.3"
				></path>
				<path d="M91.745 354.955H98.715V361.925H91.745z" opacity="0.3"></path>
				<path
					d="M106.269 369.48H113.239V376.45000000000005H106.269z"
					opacity="0.3"
				></path>
				<path
					d="M106.269 340.43H113.239V347.40000000000003H106.269z"
					opacity="0.3"
				></path>
				<path
					d="M106.269 354.955H113.239V361.925H106.269z"
					opacity="0.3"
				></path>
			</g>
			<path
				fill="url(#paint0_linear_8717_42860)"
				d="M11.529 367.985H18.999V383.962H11.529z"
			></path>
			<path
				fill="url(#paint1_linear_8717_42860)"
				d="M19.74 336.166H27.599999999999998V384.196H19.74z"
			></path>
			<path
				fill="url(#paint2_linear_8717_42860)"
				d="M28.475 374.59H36.335V384.19599999999997H28.475z"
			></path>
			<path
				fill="url(#paint3_linear_8717_42860)"
				d="M37.205 376.337H44.190999999999995V384.197H37.205z"
			></path>
			<path
				fill="url(#paint4_linear_8717_42860)"
				d="M45.941 373.717H52.927V384.19599999999997H45.941z"
			></path>
			<path
				fill="url(#paint5_linear_8717_42860)"
				d="M53.799 373.717H61.659V384.19599999999997H53.799z"
			></path>
			<path
				fill="url(#paint6_linear_8717_42860)"
				d="M0 0H7.853V2.356H0z"
				transform="matrix(-1 0 0 1 112.84 381.606)"
			></path>
			<path
				fill="url(#paint7_linear_8717_42860)"
				d="M0 0H7.853V5.497H0z"
				transform="matrix(-1 0 0 1 104.205 378.464)"
			></path>
			<path
				fill="url(#paint8_linear_8717_42860)"
				d="M0 0H7.853V10.994H0z"
				transform="matrix(-1 0 0 1 95.563 372.967)"
			></path>
			<path
				fill="url(#paint9_linear_8717_42860)"
				d="M0 0H7.86V7.86H0z"
				transform="matrix(-1 0 0 1 86.991 376.336)"
			></path>
			<path
				fill="url(#paint10_linear_8717_42860)"
				d="M0 0H7.068V18.847H0z"
				transform="matrix(-1 0 0 1 78.286 365.114)"
			></path>
			<path
				fill="url(#paint11_linear_8717_42860)"
				d="M0 0H6.986V11.353H0z"
				transform="matrix(-1 0 0 1 69.526 372.843)"
			></path>
			<path
				fill="#505F79"
				d="M424.395 330.344l-18.002 4.796 5.284 17.506c7.647-2.489 13.173-9.671 13.173-18.142 0-1.429-.157-2.821-.455-4.16z"
			></path>
			<path
				fill="#253858"
				fillRule="evenodd"
				d="M410.05 351.702c7.699-2.51 13.262-9.747 13.262-18.284 0-1.44-.158-2.843-.458-4.192l.002-.001c.3 1.35.459 2.753.459 4.193 0 8.537-5.565 15.775-13.265 18.284zm0 0a19.21 19.21 0 01-5.964.943c-10.619 0-19.227-8.608-19.227-19.227 0-10.618 8.608-19.226 19.227-19.226 9.178 0 16.855 6.432 18.768 15.034l-18.125 4.833 5.321 17.643z"
				clipRule="evenodd"
			></path>
			<path stroke="#253858" d="M409.471 317.269l-6.153-6.153h-7.306"></path>
			<path stroke="#505F79" d="M412.545 348.032l5.274 5.383h6.262"></path>
			<circle cx="394.858" cy="311.117" r="1.038" stroke="#253858"></circle>
			<circle cx="425.617" cy="353.413" r="1.038" stroke="#505F79"></circle>
			<path
				stroke="#505F79"
				strokeLinecap="round"
				d="M391.011 309.578h-8.46M391.01 311.116h-8.459M391.01 312.654h-8.459M429.726 356.884h-8.46M429.726 358.422h-8.459M429.726 359.96h-8.459"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M364.872 57.015H372.127V64.27H364.872z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M364.872 26.858H372.127V34.113H364.872z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M364.872 41.937H372.127V49.192H364.872z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M364.872 11.78H372.127V19.035H364.872z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M379.952 57.015H387.207V64.27H379.952z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M379.952 26.858H387.207V34.113H379.952z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M379.952 41.937H387.207V49.192H379.952z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M379.952 11.78H387.207V19.035H379.952z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M372.411 57.015H379.666V64.27H372.411z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M372.411 26.858H379.666V34.113H372.411z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M372.411 41.937H379.666V49.192H372.411z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M372.411 11.78H379.666V19.035H372.411z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M387.491 57.015H394.746V64.27H387.491z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M387.491 26.858H394.746V34.113H387.491z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M387.491 41.937H394.746V49.192H387.491z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M387.491 11.78H394.746V19.035H387.491z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M402.571 57.015H409.826V64.27H402.571z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M402.571 26.858H409.826V34.113H402.571z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M402.571 41.937H409.826V49.192H402.571z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M379.952 49.476H387.207V56.731H379.952z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M379.952 19.319H387.207V26.573999999999998H379.952z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M379.952 34.398H387.207V41.653000000000006H379.952z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M372.411 49.476H379.666V56.731H372.411z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M372.411 19.319H379.666V26.573999999999998H372.411z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M372.411 34.398H379.666V41.653000000000006H372.411z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M387.491 49.476H394.746V56.731H387.491z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M387.491 19.319H394.746V26.573999999999998H387.491z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M387.491 34.398H394.746V41.653000000000006H387.491z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M402.571 11.78H409.826V19.035H402.571z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M395.032 57.015H402.287V64.27H395.032z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M395.032 26.858H402.287V34.113H395.032z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M395.032 41.937H402.287V49.192H395.032z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M395.032 11.78H402.287V19.035H395.032z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M410.109 57.015H417.364V64.27H410.109z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M410.109 26.858H417.364V34.113H410.109z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M410.109 41.937H417.364V49.192H410.109z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M410.109 11.78H417.364V19.035H410.109z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M364.872 49.476H372.127V56.731H364.872z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M364.872 19.319H372.127V26.573999999999998H364.872z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M364.872 34.398H372.127V41.653000000000006H364.872z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M402.571 49.476H409.826V56.731H402.571z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M402.571 19.319H409.826V26.573999999999998H402.571z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M402.571 34.398H409.826V41.653000000000006H402.571z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M395.032 49.476H402.287V56.731H395.032z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M395.032 19.319H402.287V26.573999999999998H395.032z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M395.032 34.398H402.287V41.653000000000006H395.032z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M410.109 49.476H417.364V56.731H410.109z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M410.109 19.319H417.364V26.573999999999998H410.109z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M410.109 34.398H417.364V41.653000000000006H410.109z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M417.721 57.015H424.976V64.27H417.721z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M417.721 26.858H424.976V34.113H417.721z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M417.721 41.937H424.976V49.192H417.721z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M417.721 11.78H424.976V19.035H417.721z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M432.803 57.015H440.058V64.27H432.803z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M432.803 26.858H440.058V34.113H432.803z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M432.803 41.937H440.058V49.192H432.803z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M432.803 11.78H440.058V19.035H432.803z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M425.262 57.015H432.517V64.27H425.262z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M425.262 26.858H432.517V34.113H425.262z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M425.262 41.937H432.517V49.192H425.262z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M425.262 11.78H432.517V19.035H425.262z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M440.342 57.015H447.597V64.27H440.342z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M440.342 26.858H447.597V34.113H440.342z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M440.342 41.937H447.597V49.192H440.342z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M440.342 11.78H447.597V19.035H440.342z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M455.419 57.015H462.674V64.27H455.419z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M455.419 26.858H462.674V34.113H455.419z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M455.419 41.937H462.674V49.192H455.419z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M432.803 49.476H440.058V56.731H432.803z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M432.803 19.319H440.058V26.573999999999998H432.803z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M432.803 34.398H440.058V41.653000000000006H432.803z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M425.262 49.476H432.517V56.731H425.262z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M425.262 19.319H432.517V26.573999999999998H425.262z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M425.262 34.398H432.517V41.653000000000006H425.262z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M440.342 49.476H447.597V56.731H440.342z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M440.342 19.319H447.597V26.573999999999998H440.342z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M440.342 34.398H447.597V41.653000000000006H440.342z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M455.419 11.78H462.674V19.035H455.419z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M447.883 57.015H455.138V64.27H447.883z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M447.883 26.858H455.138V34.113H447.883z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M447.883 41.937H455.138V49.192H447.883z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M447.883 11.78H455.138V19.035H447.883z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M462.958 57.015H470.213V64.27H462.958z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M462.958 26.858H470.213V34.113H462.958z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M462.958 41.937H470.213V49.192H462.958z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M462.958 11.78H470.213V19.035H462.958z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M417.721 49.476H424.976V56.731H417.721z"
				opacity="0.3"
			></path>
			<path
				stroke="#253858"
				d="M417.657 12.036v52.2M364.944 26.687h104.913"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M417.721 19.319H424.976V26.573999999999998H417.721z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M417.721 34.398H424.976V41.653000000000006H417.721z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M455.419 49.476H462.674V56.731H455.419z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M455.419 19.319H462.674V26.573999999999998H455.419z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M455.419 34.398H462.674V41.653000000000006H455.419z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M447.883 49.476H455.138V56.731H447.883z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M447.883 19.319H455.138V26.573999999999998H447.883z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M447.883 34.398H455.138V41.653000000000006H447.883z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M462.958 49.476H470.213V56.731H462.958z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M462.958 19.319H470.213V26.573999999999998H462.958z"
				opacity="0.3"
			></path>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M462.958 34.398H470.213V41.653000000000006H462.958z"
				opacity="0.3"
			></path>
			<path
				stroke="#0B1730"
				strokeDasharray="1 1"
				strokeWidth="0.5"
				d="M368.947 50.442c13.931-12.25 52.315-27.671 94.398 8.647"
			></path>
			<circle cx="379.989" cy="34.846" r="0.907" fill="#253858"></circle>
			<circle cx="404.442" cy="32.613" r="0.907" fill="#253858"></circle>
			<circle cx="400.119" cy="39.819" r="0.907" fill="#253858"></circle>
			<circle cx="427.5" cy="34.054" r="0.907" fill="#253858"></circle>
			<circle cx="456.325" cy="41.26" r="0.907" fill="#253858"></circle>
			<circle cx="426.059" cy="41.26" r="0.907" fill="#253858"></circle>
			<circle cx="467.855" cy="61.437" r="0.907" fill="#253858"></circle>
			<circle cx="460.65" cy="61.437" r="0.907" fill="#253858"></circle>
			<circle cx="456.325" cy="45.584" r="0.907" fill="#253858"></circle>
			<circle cx="371.833" cy="44.818" r="0.907" fill="#253858"></circle>
			<circle cx="377.269" cy="40.285" r="0.907" fill="#253858"></circle>
			<circle cx="375.458" cy="47.538" r="0.907" fill="#253858"></circle>
			<circle cx="405.883" cy="39.819" r="0.907" fill="#253858"></circle>
			<circle
				cx="384.102"
				cy="39.819"
				r="0.657"
				stroke="#253858"
				strokeWidth="0.5"
			></circle>
			<circle
				cx="430.383"
				cy="38.378"
				r="0.657"
				stroke="#253858"
				strokeWidth="0.5"
			></circle>
			<circle
				cx="427.5"
				cy="62.878"
				r="0.657"
				stroke="#253858"
				strokeWidth="0.5"
			></circle>
			<circle
				cx="464.972"
				cy="57.113"
				r="0.657"
				stroke="#253858"
				strokeWidth="0.5"
			></circle>
			<circle
				cx="452.001"
				cy="48.466"
				r="0.657"
				stroke="#253858"
				strokeWidth="0.5"
			></circle>
			<circle
				cx="381.383"
				cy="45.258"
				r="0.657"
				stroke="#253858"
				strokeWidth="0.5"
			></circle>
			<circle
				cx="369.113"
				cy="18.528"
				r="0.657"
				stroke="#253858"
				strokeWidth="0.5"
			></circle>
			<circle
				cx="377.269"
				cy="36.659"
				r="0.657"
				stroke="#253858"
				strokeWidth="0.5"
			></circle>
			<path
				fill="#F3F6FA"
				d="M212.53 204.711a6 6 0 015.656-3.998h37.601a6 6 0 015.656 3.998l36.715 103.719c1.382 3.904-1.515 8.002-5.657 8.002h-111.03c-4.142 0-7.038-4.098-5.656-8.002l36.715-103.719z"
			></path>
			<mask
				id="mask0_8717_42860"
				style={{ maskType: "alpha" }}
				width="124"
				height="117"
				x="175"
				y="200"
				maskUnits="userSpaceOnUse"
			>
				<path
					fill="#F3F6FA"
					d="M212.53 204.711a6 6 0 015.656-3.998h37.601a6 6 0 015.656 3.998l36.715 103.719c1.382 3.904-1.515 8.002-5.657 8.002h-111.03c-4.142 0-7.038-4.098-5.656-8.002l36.715-103.719z"
				></path>
			</mask>
			<g mask="url(#mask0_8717_42860)">
				<mask
					id="mask1_8717_42860"
					style={{ maskType: "alpha" }}
					width="148"
					height="74"
					x="167"
					y="259"
					maskUnits="userSpaceOnUse"
				>
					<path
						fill="#253858"
						d="M200.631 262.136c-23.349.467-31.576 7.204-32.77 10.514v60.167h146.44c-6.315-27.455-19.764-80.495-23.041-73.018-4.096 9.346-61.444 1.753-90.629 2.337z"
					></path>
				</mask>
				<g mask="url(#mask1_8717_42860)">
					<g filter="url(#filter0_f_8717_42860)">
						<circle
							cx="271.29"
							cy="338.961"
							r="58.371"
							fill="url(#paint12_radial_8717_42860)"
						></circle>
					</g>
					<g filter="url(#filter1_f_8717_42860)">
						<circle
							cx="199.606"
							cy="272.397"
							r="58.371"
							fill="url(#paint13_radial_8717_42860)"
						></circle>
					</g>
					<g filter="url(#filter2_f_8717_42860)">
						<circle
							cx="204.726"
							cy="308.239"
							r="58.371"
							fill="url(#paint14_radial_8717_42860)"
						></circle>
					</g>
					<g filter="url(#filter3_f_8717_42860)">
						<circle
							cx="253.369"
							cy="281.102"
							r="37.378"
							fill="url(#paint15_radial_8717_42860)"
						></circle>
					</g>
					<g filter="url(#filter4_f_8717_42860)">
						<circle
							cx="299.451"
							cy="271.885"
							r="46.595"
							fill="url(#paint16_radial_8717_42860)"
						></circle>
					</g>
				</g>
			</g>
			<path fill="#F3F6FA" d="M212.922 150.534H261.053V225.29H212.922z"></path>
			<rect
				width="59.395"
				height="8.192"
				x="206.777"
				y="142.342"
				fill="#F3F6FA"
				rx="4.096"
			></rect>
			<mask id="path-382-inside-1_8717_42860" fill="#fff">
				<path
					fillRule="evenodd"
					d="M210.876 142.342a4.096 4.096 0 000 8.193h51.203a4.096 4.096 0 000-8.193h-51.203zm2.049 8.193h48.131v53.306c.15.276.28.567.388.87l36.714 103.719c1.383 3.905-1.514 8.002-5.656 8.002h-111.03c-4.142 0-7.038-4.097-5.656-8.002l36.715-103.719c.109-.307.241-.601.394-.881v-53.295z"
					clipRule="evenodd"
				></path>
			</mask>
			<path
				fill="#DFE1E6"
				d="M261.056 150.535h2v-2h-2v2zm-48.131 0v-2h-2v2h2zm48.131 53.306h-2v.51l.244.447 1.756-.957zm.388.87l1.885-.667-1.885.667zm36.714 103.719l-1.885.668 1.885-.668zm-122.342 0l-1.885-.667 1.885.667zm36.715-103.719l1.885.668-1.885-.668zm.394-.881l1.754.961.246-.449v-.512h-2zm-4.145-57.391c0-1.158.938-2.097 2.096-2.097v-4a6.097 6.097 0 00-6.096 6.097h4zm2.096 2.096a2.096 2.096 0 01-2.096-2.096h-4a6.096 6.096 0 006.096 6.096v-4zm51.203 0h-51.203v4h51.203v-4zm2.096-2.096a2.096 2.096 0 01-2.096 2.096v4a6.096 6.096 0 006.096-6.096h-4zm-2.096-2.097c1.158 0 2.096.939 2.096 2.097h4a6.097 6.097 0 00-6.096-6.097v4zm-51.203 0h51.203v-4h-51.203v4zm50.18 4.193h-48.131v4h48.131v-4zm2 55.306v-53.306h-4v53.306h4zm.273.203a8.01 8.01 0 00-.517-1.16l-3.512 1.914c.1.184.187.378.259.581l3.77-1.335zm36.715 103.719l-36.715-103.719-3.77 1.335 36.714 103.719 3.771-1.335zm-7.542 10.669c5.523 0 9.385-5.463 7.542-10.669l-3.771 1.335c.922 2.603-1.009 5.334-3.771 5.334v4zm-111.03 0h111.03v-4h-111.03v4zm-7.541-10.669c-1.843 5.206 2.019 10.669 7.541 10.669v-4c-2.761 0-4.692-2.731-3.77-5.334l-3.771-1.335zm36.715-103.719l-36.715 103.719 3.771 1.335 36.714-103.719-3.77-1.335zm.525-1.175a7.903 7.903 0 00-.525 1.175l3.77 1.335a4 4 0 01.263-.588l-3.508-1.922zm-.246-52.334v53.295h4v-53.295h-4z"
				mask="url(#path-382-inside-1_8717_42860)"
			></path>
			<path
				fill="#253858"
				d="M141.671 315.545l.652-4.783 3.479.652.87.87c.786.787 3.334 2.609 4.783 3.261.725.29 1.913.87.87.87-1.044 0-5.218-.29-7.175-.435l-.87-1.087-1.087.652h-1.522zM155.366 314.241l.652-4.784 3.479.653.87.869c.787.787 3.334 2.609 4.783 3.262.725.29 1.913.869.87.869-1.044 0-5.218-.289-7.175-.434l-.87-1.088-1.087.653h-1.522z"
			></path>
			<path
				fill="#0B1730"
				d="M141.236 310.544l5-46.094c6.61-2.261 11.017-1.929 12.394-.986.507 2.029 1.652 8.422 2.174 13.814.522 5.392-.217 24.135-.652 32.832-1.015.144-3.305.434-4.349.434-1.043 0-2.029-22.177-2.391-33.266-1.957 11.306-6.132 33.962-7.176 34.136-1.043.174-3.768-.507-5-.87z"
			></path>
			<path
				fill="#C1C7D0"
				d="M173.385 246.218c-.521.87-6.305 3.406-9.131 4.566.362 1.087.782 3.609-.435 5.001 8.914-2.609 10.219-7.393 12.393-8.262 2.174-.87 2.826-3.262 1.305-4.131-1.522-.87-2.61 2.391-2.827 1.956-.217-.434.87-1.956 0-1.522-.87.435-.652 1.305-1.305 2.392z"
			></path>
			<path
				fill="#305CBD"
				d="M163.816 255.753c1.044-.173.87-3.406.653-5-2.175-1.522-8.697-6.958-11.959-6.958-2.609 0-1.667 2.319-.87 3.479 3.044 2.681 9.306 8.132 10.002 8.479.87.435.87.218 2.174 0z"
			></path>
			<path
				fill="#1753E2"
				d="M154.716 242.707c-2.957.522-5.145.218-5.87 0-1.667.653-5.044 2.262-5.218 3.479-.218 1.522 1.956 12.393 2.391 14.35.435 1.957-.402 3.697.435 4.131 3.653 2.262 9.784.653 12.393-.434-.145-1.812-.347-5.784 0-7.175.348-1.392.145-6.523 0-8.915.145-.797.348-2.652 0-3.696-.434-1.305-.434-2.392-4.131-1.74z"
			></path>
			<path
				fill="#F3F6FA"
				fillRule="evenodd"
				d="M195.805 208.398l-14.059-4.658-1.048 3.164 2.462.815-4.176 12.605c-7.358-.25-14.306 4.327-16.739 11.67-2.958 8.929 1.882 18.566 10.811 21.524 8.929 2.959 18.566-1.882 21.524-10.811 2.433-7.342-.407-15.162-6.458-19.355l4.176-12.605 2.459.814 1.048-3.163z"
				clipRule="evenodd"
			></path>
			<mask
				id="mask2_8717_42860"
				style={{ maskType: "alpha" }}
				width="35"
				height="35"
				x="161"
				y="220"
				maskUnits="userSpaceOnUse"
			>
				<circle
					cx="178.413"
					cy="237.351"
					r="17.032"
					fill="#F3F6FA"
					transform="rotate(18.33 178.413 237.351)"
				></circle>
			</mask>
			<g mask="url(#mask2_8717_42860)">
				<path
					fill="#5F85EB"
					d="M176.073 238.525c-5.48.057-13.181-4.757-16.347-7.171-4.819 22.197 18.194 25.141 27.035 25.535 7.073.314 10.115-7.376 10.752-11.26.017-1.035-1.025-3.499-5.337-5.084-5.39-1.981-9.254-2.091-16.103-2.02z"
				></path>
			</g>
			<g style={{ mixBlendMode: "multiply" }}>
				<mask
					id="mask3_8717_42860"
					style={{ maskType: "alpha" }}
					width="35"
					height="52"
					x="161"
					y="203"
					maskUnits="userSpaceOnUse"
				>
					<path
						fill="#F3F6FA"
						fillRule="evenodd"
						d="M195.805 208.398l-14.059-4.658-1.048 3.164 2.462.815-4.176 12.605c-7.358-.25-14.306 4.327-16.739 11.67-2.958 8.929 1.882 18.566 10.811 21.524 8.929 2.959 18.566-1.882 21.524-10.811 2.433-7.342-.407-15.162-6.458-19.355l4.176-12.605 2.459.814 1.048-3.163z"
						clipRule="evenodd"
					></path>
				</mask>
				<g mask="url(#mask3_8717_42860)">
					<path
						fill="#EBECF0"
						d="M185.383 240.44c-1.088 10.859-20.183 7.941-22.239 4.139-.831 4.47 3.07 14.396 25.324 18.337 22.254 3.94 15.254-35.252 8.973-55.34-1.328-.896-3.866-1.398-3.63-.228.295 1.463-1.285 1.524-3.272 3.401-1.987 1.877-4.314 9.624-3.954 14.878.301 4.39.235.468-1.202 14.813z"
					></path>
				</g>
			</g>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M183.397 207.798l.079-.237-.238-.079-2.223-.737.891-2.688 13.585 4.5-.891 2.689-2.223-.737-.238-.078-.078.237-4.176 12.605-.059.178.154.106c5.962 4.131 8.761 11.838 6.364 19.072-2.915 8.798-12.41 13.567-21.209 10.652-8.798-2.915-13.567-12.41-10.652-21.208 2.397-7.234 9.243-11.745 16.492-11.499l.187.006.059-.177 4.176-12.605z"
			></path>
			<path
				fill="#C1C7D0"
				d="M148.84 242.707l-.435-4.348 4.348-5.653 4.132 2.174c0 2.609 0 2.609-.218 3.479-.094.377-.797 2.174-1.087 3.044-.435-.073-1.305-.087-1.305.435 0 .652.653.869.435 1.304-.217.435-1.957 1.087-3.261 1.087-1.044 0-2.174-1.014-2.609-1.522z"
			></path>
			<path
				fill="#253858"
				d="M148.192 238.359l-.435-3.479c.435-3.914 3.044-4.566 5.654-4.131 2.087.348 3.188 2.899 3.478 4.131-.652-.362-2.261-1.131-3.478-1.305-1.522-.217-.87 1.74-.87 2.175 0 .434-.218 1.304-1.305 1.304s-.435-.217-.869 0c-.435.218-.218 1.087-.653 1.74-.348.521-1.159-.073-1.522-.435z"
			></path>
			<path
				fill="#C1C7D0"
				d="M156.449 253.579c.347-.696 0-1.884-.218-2.392 4.566-4.566 7.61-7.392 7.61-8.914 0-1.522 1.305-4.131 1.739-3.696.295.295.299.818.249 1.148.133-.542.605-1.426 1.708-1.583 1.522-.218 1.305 1.739 1.305 2.392 0 .652-.652 1.956-1.522 2.826-.87.87-.435.218-.87.87s-3.479 6.523-4.566 8.479c-1.087 1.957-4.131 4.349-5.653 4.566-1.522.218-1.522-.217-1.739-.869-.218-.653 1.522-1.957 1.957-2.827z"
			></path>
			<path
				fill="url(#paint17_linear_8717_42860)"
				d="M145.01 247.24c.155-1.69 7.606 1.708 11.226 3.948.87 2.174 0 2.634 0 2.826 0 .192-1.142 1.099-1.336 1.483-.194.384-.194 1.344-.388 1.344s-9.114-5.569-9.308-5.953c-.194-.384-.388-1.536-.194-3.648z"
			></path>
			<path fill="#505F79" d="M353.039 123.259H373.52V129.403H353.039z"></path>
			<path fill="#DFE1E6" d="M245.512 123.259H353.038V129.403H245.512z"></path>
			<path
				fill="#305CBD"
				d="M272.969 97.992c-.924.076-1.47-2.709-1.627-4.11 1.522-1.761 5.9-7.782 8.673-8.481 2.218-.56 1.914 1.614 1.485 2.77-2.012 2.933-6.166 8.91-6.682 9.355-.646.556-.693.371-1.849.466z"
			></path>
			<path
				fill="#C1C7D0"
				d="M262.794 91.94c.63.627 6.091 1.542 8.742 1.922-.074 1.002.109 3.236 1.443 4.157-8.138-.305-10.273-4.091-12.308-4.364-2.035-.273-3.103-2.166-1.995-3.232 1.107-1.066 2.731 1.474 2.822 1.057.092-.416-1.159-1.477-.326-1.294.832.183.834.97 1.622 1.754z"
			></path>
			<path
				fill="#1753E2"
				d="M277.913 84.948c2.625-.19 4.421-.919 4.99-1.26 1.557.197 4.773.84 5.182 1.838.512 1.248.996 10.956 1.046 12.712.051 1.757 1.135 3.056.517 3.605-2.62 2.706-8.177 2.654-10.629 2.29-.265-1.571-.945-4.991-1.539-6.1-.594-1.108-1.523-5.513-1.913-7.578-.294-.646-.865-2.18-.793-3.142.09-1.202-.143-2.126 3.139-2.365z"
			></path>
			<path
				fill="#F3F6FA"
				fillRule="evenodd"
				d="M232.898 70.775l12.105-8.534 1.92 2.724-12.105 8.534-1.92-2.724zm11.907-4.317l-7.868 5.547 7.65 10.852c-4.576 5.767-5.028 14.076-.571 20.398 5.42 7.688 16.046 9.527 23.735 4.107 7.688-5.42 9.527-16.046 4.107-23.734-4.457-6.322-12.433-8.689-19.402-6.317l-7.651-10.853z"
				clipRule="evenodd"
			></path>
			<mask
				id="mask4_8717_42860"
				style={{ maskType: "alpha" }}
				width="35"
				height="35"
				x="240"
				y="76"
				maskUnits="userSpaceOnUse"
			>
				<circle
					r="17.032"
					fill="#F3F6FA"
					transform="scale(-1 1) rotate(35.182 -276.329 -360.057)"
				></circle>
			</mask>
			<g mask="url(#mask4_8717_42860)">
				<path
					fill="#F4A362"
					d="M260.514 93.887c5.26-1.534 11.235-8.374 13.565-11.602 11.047 19.847-10.124 29.335-18.471 32.275-6.678 2.352-11.819-4.126-13.554-7.659-.317-.985-.034-3.646 3.633-6.413 4.584-3.458 8.251-4.683 14.827-6.601z"
				></path>
			</g>
			<g style={{ mixBlendMode: "multiply" }}>
				<mask
					id="mask5_8717_42860"
					style={{ maskType: "alpha" }}
					width="43"
					height="49"
					x="232"
					y="62"
					maskUnits="userSpaceOnUse"
				>
					<path
						fill="#F3F6FA"
						fillRule="evenodd"
						d="M232.898 70.775l12.105-8.534 1.92 2.724-12.105 8.534-1.92-2.724zm11.907-4.317l-7.868 5.547 7.65 10.852c-4.576 5.767-5.028 14.076-.571 20.398 5.42 7.688 16.046 9.527 23.735 4.107 7.688-5.42 9.527-16.046 4.107-23.734-4.457-6.322-12.433-8.689-19.402-6.317l-7.651-10.853z"
						clipRule="evenodd"
					></path>
				</mask>
				<g mask="url(#mask5_8717_42860)">
					<path
						fill="#EBECF0"
						d="M252.161 98.42c4.189 10.077 21.618 1.748 22.484-2.486 2.091 4.038 1.235 14.668-18.921 24.891-20.156 10.222-24.819-29.316-24.631-50.363 1.012-1.242 3.295-2.458 3.408-1.27.141 1.486 1.672 1.087 4.118 2.307 2.446 1.22 6.918 7.96 8.097 13.092.985 4.29-.09.516 5.445 13.83z"
					></path>
				</g>
			</g>
			<path
				stroke="#C1C7D0"
				strokeWidth="0.5"
				d="M244.601 66.602l-.144-.204.205-.144 1.914-1.35-1.632-2.314-11.697 8.245 1.632 2.315 1.914-1.35.205-.144.144.205 7.651 10.853.107.153-.116.146c-4.509 5.682-4.953 13.869-.562 20.098 5.341 7.575 15.811 9.387 23.386 4.047 7.575-5.341 9.387-15.81 4.047-23.386-4.391-6.229-12.251-8.562-19.118-6.224l-.177.06-.108-.153-7.651-10.853z"
			></path>
			<path
				fill="#C1C7D0"
				d="M278.924 94.248c-.37-.527-.629-.742-1.241-.682-4.891 2.852-8.946 5.353-10.237 5.013-1.29-.34-3.793.184-3.522.65.184.316.628.435.918.466-.489-.008-1.343.196-1.723 1.096-.524 1.242 1.183 1.494 1.736 1.64.553.145 1.805-.116 2.736-.66.932-.543.282-.32.932-.543.65-.223 6.307-1.493 8.209-1.978 1.901-.485 4.609-2.532 5.133-3.774s.155-1.339-.349-1.669c-.505-.33-2.101 1.139-2.592.441z"
			></path>
			<path
				fill="url(#paint18_linear_8717_42860)"
				d="M287.131 86.719c-.494-1.403-6.099 3.083-8.695 5.765l-.756 1.084c.186-.117 1.955 1.48 2.816 2.292.247.285.453 1.1.618 1.06.165-.042 6.553-6.69 6.635-7.058.083-.368.001-1.39-.618-3.143z"
			></path>
			<path
				fill="#0B1730"
				d="M286.132 118.645c7.9-12.767 5.299-14.491 3.702-17.116l-5.451 1.152-1.287 3.897c-3.045 4.255-2.098 11.829-1.243 15.083l14.247-.344.383-4.303-10.351 1.631z"
			></path>
			<path
				fill="#1D1D1D"
				d="M297.388 117.419l-1.035.163-.476 2.087.69 1.355-.683 1.241 1.355-.689.149-4.157zM271.143 123.121l5.466-4.24 4.393-.624.527 4.561-.869-.049-.192-1.231-1.111 1.158-8.167 1.125-.047-.7z"
			></path>
			<path
				fill="#253858"
				d="M272.94 103.864c6.161.287 13.733-1.592 16.75-2.568 1.048 1.237 3.063 5.648.909 7.392-2.071 2.082-8.03 1.961-13.299.56l4.298 9.162c-1.734.603-5.306 2.263-5.202 1.808.13-.569-2.567-5.375-5.402-7.817-2.836-2.443-5.756-8.896 1.946-8.537z"
			></path>
			<path
				fill="#C1C7D0"
				d="M282.903 83.69l-.564-3.79-4.909-3.873-3.046 2.734c.56 2.218.56 2.218.932 2.911.161.3 1.144 1.677 1.577 2.354.354-.155 1.09-.353 1.202.09.14.555-.368.88-.089 1.202.278.323 1.896.505 3.005.225.887-.224 1.631-1.33 1.892-1.854z"
			></path>
			<path
				fill="#253858"
				d="M282.523 79.853l-.376-3.051c-1.21-3.234-3.568-3.228-5.692-2.299-1.7.744-2.089 3.149-2.071 4.258.476-.448 1.679-1.446 2.677-1.855 1.247-.512 1.113 1.292 1.206 1.662.093.37.465 1.062 1.389.829.924-.234.323-.279.739-.187.416.092.418.878.928 1.339.408.369.97-.31 1.2-.697z"
			></path>
			<circle
				cx="281.548"
				cy="74.315"
				r="2.335"
				fill="#253858"
				transform="rotate(-14.166 281.548 74.315)"
			></circle>
			<defs>
				<filter
					id="filter0_f_8717_42860"
					width="164.743"
					height="164.743"
					x="188.919"
					y="256.59"
					colorInterpolationFilters="sRGB"
					filterUnits="userSpaceOnUse"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
					<feBlend
						in="SourceGraphic"
						in2="BackgroundImageFix"
						result="shape"
					></feBlend>
					<feGaussianBlur
						result="effect1_foregroundBlur_8717_42860"
						stdDeviation="12"
					></feGaussianBlur>
				</filter>
				<filter
					id="filter1_f_8717_42860"
					width="164.743"
					height="164.743"
					x="117.234"
					y="190.026"
					colorInterpolationFilters="sRGB"
					filterUnits="userSpaceOnUse"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
					<feBlend
						in="SourceGraphic"
						in2="BackgroundImageFix"
						result="shape"
					></feBlend>
					<feGaussianBlur
						result="effect1_foregroundBlur_8717_42860"
						stdDeviation="12"
					></feGaussianBlur>
				</filter>
				<filter
					id="filter2_f_8717_42860"
					width="164.743"
					height="164.743"
					x="122.355"
					y="225.868"
					colorInterpolationFilters="sRGB"
					filterUnits="userSpaceOnUse"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
					<feBlend
						in="SourceGraphic"
						in2="BackgroundImageFix"
						result="shape"
					></feBlend>
					<feGaussianBlur
						result="effect1_foregroundBlur_8717_42860"
						stdDeviation="12"
					></feGaussianBlur>
				</filter>
				<filter
					id="filter3_f_8717_42860"
					width="122.756"
					height="122.756"
					x="191.99"
					y="219.724"
					colorInterpolationFilters="sRGB"
					filterUnits="userSpaceOnUse"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
					<feBlend
						in="SourceGraphic"
						in2="BackgroundImageFix"
						result="shape"
					></feBlend>
					<feGaussianBlur
						result="effect1_foregroundBlur_8717_42860"
						stdDeviation="12"
					></feGaussianBlur>
				</filter>
				<filter
					id="filter4_f_8717_42860"
					width="141.189"
					height="141.189"
					x="228.856"
					y="201.291"
					colorInterpolationFilters="sRGB"
					filterUnits="userSpaceOnUse"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
					<feBlend
						in="SourceGraphic"
						in2="BackgroundImageFix"
						result="shape"
					></feBlend>
					<feGaussianBlur
						result="effect1_foregroundBlur_8717_42860"
						stdDeviation="12"
					></feGaussianBlur>
				</filter>
				<linearGradient
					id="paint0_linear_8717_42860"
					x1="15.264"
					x2="15.264"
					y1="367.985"
					y2="383.962"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#D2F9E1" stopOpacity="0"></stop>
					<stop offset="0" stopColor="#EBECF0" stopOpacity="0"></stop>
					<stop offset="1" stopColor="#253858"></stop>
				</linearGradient>
				<linearGradient
					id="paint1_linear_8717_42860"
					x1="23.669"
					x2="23.669"
					y1="336.166"
					y2="384.196"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#D2F9E1" stopOpacity="0"></stop>
					<stop offset="0" stopColor="#EBECF0" stopOpacity="0"></stop>
					<stop offset="1" stopColor="#253858"></stop>
				</linearGradient>
				<linearGradient
					id="paint2_linear_8717_42860"
					x1="32.405"
					x2="32.405"
					y1="374.59"
					y2="384.196"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#D2F9E1" stopOpacity="0"></stop>
					<stop offset="0" stopColor="#EBECF0" stopOpacity="0"></stop>
					<stop offset="1" stopColor="#253858"></stop>
				</linearGradient>
				<linearGradient
					id="paint3_linear_8717_42860"
					x1="40.699"
					x2="40.699"
					y1="376.337"
					y2="384.196"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#D2F9E1" stopOpacity="0"></stop>
					<stop offset="0" stopColor="#EBECF0" stopOpacity="0"></stop>
					<stop offset="1" stopColor="#253858"></stop>
				</linearGradient>
				<linearGradient
					id="paint4_linear_8717_42860"
					x1="49.434"
					x2="49.434"
					y1="373.717"
					y2="384.196"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#D2F9E1" stopOpacity="0"></stop>
					<stop offset="0" stopColor="#EBECF0" stopOpacity="0"></stop>
					<stop offset="1" stopColor="#253858"></stop>
				</linearGradient>
				<linearGradient
					id="paint5_linear_8717_42860"
					x1="57.729"
					x2="57.729"
					y1="373.717"
					y2="384.196"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#505F79" stopOpacity="0"></stop>
					<stop offset="1" stopColor="#0B1730"></stop>
				</linearGradient>
				<linearGradient
					id="paint6_linear_8717_42860"
					x1="3.927"
					x2="3.927"
					y1="0"
					y2="2.356"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#D2F9E1" stopOpacity="0"></stop>
					<stop offset="0" stopColor="#EBECF0" stopOpacity="0"></stop>
					<stop offset="1" stopColor="#253858"></stop>
				</linearGradient>
				<linearGradient
					id="paint7_linear_8717_42860"
					x1="3.927"
					x2="3.927"
					y1="0"
					y2="5.497"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#D2F9E1" stopOpacity="0"></stop>
					<stop offset="0" stopColor="#EBECF0" stopOpacity="0"></stop>
					<stop offset="1" stopColor="#253858"></stop>
				</linearGradient>
				<linearGradient
					id="paint8_linear_8717_42860"
					x1="3.927"
					x2="3.927"
					y1="0"
					y2="10.994"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#D2F9E1" stopOpacity="0"></stop>
					<stop offset="0" stopColor="#EBECF0" stopOpacity="0"></stop>
					<stop offset="1" stopColor="#253858"></stop>
				</linearGradient>
				<linearGradient
					id="paint9_linear_8717_42860"
					x1="3.93"
					x2="3.93"
					y1="0"
					y2="7.86"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#D2F9E1" stopOpacity="0"></stop>
					<stop offset="0" stopColor="#EBECF0" stopOpacity="0"></stop>
					<stop offset="1" stopColor="#253858"></stop>
				</linearGradient>
				<linearGradient
					id="paint10_linear_8717_42860"
					x1="3.534"
					x2="3.534"
					y1="0"
					y2="18.847"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#D2F9E1" stopOpacity="0"></stop>
					<stop offset="0" stopColor="#EBECF0" stopOpacity="0"></stop>
					<stop offset="1" stopColor="#253858"></stop>
				</linearGradient>
				<linearGradient
					id="paint11_linear_8717_42860"
					x1="3.493"
					x2="3.493"
					y1="0"
					y2="11.353"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#505F79" stopOpacity="0"></stop>
					<stop offset="1" stopColor="#0B1730"></stop>
				</linearGradient>
				<radialGradient
					id="paint12_radial_8717_42860"
					cx="0"
					cy="0"
					r="1"
					gradientTransform="rotate(90 -33.835 305.126) scale(58.3714)"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#1753E2"></stop>
					<stop offset="1" stopColor="#CCDBFB"></stop>
				</radialGradient>
				<radialGradient
					id="paint13_radial_8717_42860"
					cx="0"
					cy="0"
					r="1"
					gradientTransform="rotate(90 -36.396 236.001) scale(58.3714)"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#1753E2"></stop>
					<stop offset="1" stopColor="#CCDBFB"></stop>
				</radialGradient>
				<radialGradient
					id="paint14_radial_8717_42860"
					cx="0"
					cy="0"
					r="1"
					gradientTransform="rotate(90 -51.757 256.483) scale(58.3714)"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#7BC598"></stop>
					<stop offset="0.49" stopColor="#AADABD"></stop>
					<stop offset="1" stopColor="#A6BFF7"></stop>
				</radialGradient>
				<radialGradient
					id="paint15_radial_8717_42860"
					cx="0"
					cy="0"
					r="1"
					gradientTransform="rotate(90 -13.867 267.236) scale(37.3782)"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#F7C49C"></stop>
					<stop offset="0.542" stopColor="#FCEADC"></stop>
					<stop offset="1" stopColor="#5F85EB"></stop>
				</radialGradient>
				<radialGradient
					id="paint16_radial_8717_42860"
					cx="0"
					cy="0"
					r="1"
					gradientTransform="rotate(90 13.783 285.668) scale(46.5947)"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#F39244"></stop>
					<stop offset="1" stopColor="#FAD8BD"></stop>
				</radialGradient>
				<linearGradient
					id="paint17_linear_8717_42860"
					x1="144.93"
					x2="155.584"
					y1="248.897"
					y2="254.332"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#1753E2"></stop>
					<stop offset="1" stopColor="#3C67F0"></stop>
				</linearGradient>
				<linearGradient
					id="paint18_linear_8717_42860"
					x1="287.555"
					x2="279.665"
					y1="88.11"
					y2="95.017"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#1753E2"></stop>
					<stop offset="1" stopColor="#3C67F0"></stop>
				</linearGradient>
			</defs>
		</svg>
	)
}
