import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { ExperimentDesignResearchIllustration } from "components/library/illustrations/lab/experiment-design-research";

export const DataDrivenDecision = () => {
	const { t } = useTranslation()

	return (
		<section className="flex flex-col gap-12 items-center text-center">
			<div className="flex flex-col gap-4">
				<h2 className="text-2xl sm:text-3xl text-theme-blue">
					{t("products-doe-page.data-driven-decision.heading")}
				</h2>
				<p className="text-lg sm:text-xl max-w-5xl">
					{t("products-doe-page.data-driven-decision.desc")}
				</p>
			</div>

			<ExperimentDesignResearchIllustration className="w-[80%]" />
		</section>
	)
}
