import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { MicroscopeResearchIllustration } from "components/library/illustrations/lab/microscope-research";
import { Pattern13Illustration } from "components/library/illustrations/patterns/pattern13";

export const UsedAtPolymerize = () => {
	const { t } = useTranslation()

	return (
		<section id="use" className="flex flex-col gap-12">
			<div className="flex flex-col gap-6 items-center text-center">
				<div className="w-20 h-20 sm:w-32 sm:h-32 rounded-full bg-[#DEE9FD] flex justify-center items-center">
					<Pattern13Illustration className="w-12 sm:w-24" />
				</div>
				<h2 className="text-theme-blue text-2xl sm:text-3xl lg:text-4xl">
					{t("products-doe-page.used-at-polymerize.heading")}
				</h2>
			</div>

			<div className="flex flex-col-reverse justify-center md:flex-row items-center gap-6 sm:gap-20">
				<MicroscopeResearchIllustration className="w-full md:w-[35%]" />

				<p className="sm:text-lg md:w-[50%] flex flex-col gap-6">
					<span>{t("products-doe-page.used-at-polymerize.desc.1")}</span>
					<span>{t("products-doe-page.used-at-polymerize.desc.2")}</span>
					<span>{t("products-doe-page.used-at-polymerize.desc.3")}</span>
				</p>
			</div>
		</section>
	)
}
