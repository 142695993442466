import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { LiveExperimentIllustration } from "components/library/illustrations/lab/live-experiment";
import { Pattern12Illustration } from "components/library/illustrations/patterns/pattern12";

export const SolvingProblems = () => {
	const { t } = useTranslation()

	return (
		<section id="solution" className="flex flex-col gap-12">
			<div className="flex flex-col gap-6 items-center text-center">
				<div className="w-20 h-20 sm:w-32 sm:h-32 rounded-full bg-[#DEE9FD] flex justify-center items-center">
					<Pattern12Illustration className="w-10 sm:w-16" />
				</div>
				<h2 className="text-theme-blue text-2xl sm:text-3xl lg:text-4xl">
					{t("products-doe-page.solving-problems.heading")}
				</h2>
			</div>

			<div className="flex flex-col-reverse md:flex-row items-center gap-6">
				<p className="sm:text-lg md:w-[50%]">
					{t("products-doe-page.solving-problems.desc")}
				</p>
				<LiveExperimentIllustration className="w-full md:w-[50%]" />
			</div>
		</section>
	)
}
