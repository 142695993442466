import React from "react"
import { Illustration } from "types/components"
import { mergeClasses } from "utils"

export const Pattern13Illustration: Illustration = ({
	className = "",
	...props
}) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 109 79"
			className={mergeClasses({ classes: ["w-20", className] })}
			{...props}
		>
			<path
				fill="#F4A362"
				d="M21.248 44.75l-1.2-15.804c-.02-.26.32-.373.46-.153l8.496 13.38a.25.25 0 00.254.113l14.875-2.582c.275-.048.41.324.166.463L31.053 47.72a.25.25 0 00-.126.236l1.2 15.813c.02.26-.32.373-.46.153l-8.5-13.388a.25.25 0 00-.242-.114L7.793 52.296c-.278.034-.394-.345-.144-.471l13.462-6.833a.25.25 0 00.137-.242z"
			></path>
			<path
				fill="#EF8129"
				d="M30.915 47.797l.939 14.777c.011.18-.245.224-.294.05l-4.248-15.037a1.25 1.25 0 01.755-1.507l14.294-5.482c.172-.066.289.175.131.269l-11.577 6.93z"
			></path>
			<path
				fill="#7BC598"
				d="M41.989 23.644l7.907-18.703c.101-.24.456-.184.48.075L52.2 25.24c.009.1.077.186.174.216l18.576 5.826c.267.083.215.476-.065.488l-19.638.833a.25.25 0 00-.22.152l-7.91 18.71c-.102.24-.456.185-.48-.074l-1.825-20.232a.25.25 0 00-.162-.212l-18.448-6.785c-.263-.097-.193-.486.087-.485l19.468.12a.25.25 0 00.232-.153z"
			></path>
			<path
				fill="#3A8356"
				d="M51.089 32.609l-7.636 17.456c-.072.165-.32.086-.284-.09l4.059-19.978a1.25 1.25 0 011.362-.994l19.949 2.195c.182.02.176.287-.007.3l-17.443 1.111z"
			></path>
			<path
				fill="#3C67F0"
				d="M67.27 36.827l11.678-21.035c.127-.228.473-.135.469.125l-.405 24.056a.25.25 0 00.15.234l21.226 9.242c.256.112.163.497-.116.48L76.95 48.423a.25.25 0 00-.234.128L65.034 69.595c-.126.228-.473.135-.469-.126l.405-24.064a.25.25 0 00-.139-.228L43.882 34.819c-.251-.125-.14-.505.139-.473l23.003 2.608a.25.25 0 00.247-.127z"
			></path>
			<path
				fill="#305CBD"
				d="M76.793 48.414L65.565 68.063c-.089.156-.327.051-.273-.12l7.378-23.254a1.25 1.25 0 011.46-.843l23.517 5.17c.18.04.145.304-.038.296l-20.816-.898z"
			></path>
			<path
				fill="#F16363"
				d="M35.556 51.997l.497-6.45c.02-.26.374-.32.478-.082l2.597 5.926a.25.25 0 00.234.15l5.894-.114c.28-.005.356.383.094.483l-5.573 2.133a.25.25 0 00-.16.215l-.497 6.459c-.02.259-.374.319-.479.08l-2.6-5.933a.25.25 0 00-.222-.15l-5.965-.174c-.28-.008-.336-.4-.07-.487l5.6-1.838a.25.25 0 00.172-.218z"
			></path>
			<path
				fill="#B10F0F"
				d="M39.63 54.1l-.523 5.843c-.016.18-.277.184-.299.006l-.686-5.564a1.25 1.25 0 01.976-1.375l5.169-1.12c.179-.04.258.216.088.285L39.63 54.1z"
			></path>
		</svg>
	)
}
