import React from "react"
import { Container, PrimaryBtn } from "components/library"
import { Illustration } from "types/components"
import { mergeClasses } from "utils"
import { TranslationKey } from "types/translation"
import { Trans } from "gatsby-plugin-react-i18next"

type P = {
	title: TranslationKey
	content: TranslationKey
	illustration: Illustration
	titleClass?: string
	contentClass?: string
	illustrationClass?: string
}

export const HeroLayout = ({
	title,
	content,
	illustration,
	illustrationClass = "",
	contentClass = "",
	titleClass = "",
}: P) => {
	const Illustration = illustration

	return (
		<Container className="grid grid-cols-1 md:grid-cols-2 justify-between gap-12 items-center">
			<div className="flex flex-col gap-8 order-1 md:order-[-1]">
				<h1
					className={mergeClasses({
						classes: [
							"text-4xl tracking-wider font-semibold sm:text-5xl md:text-6xl break-words",
							titleClass,
						],
					})}
				>
					<Trans i18nKey={`${title}`} />
				</h1>
				<p
					className={mergeClasses({
						classes: ["sm:text-lg sm:max-w-xl md:text-2xl", contentClass],
					})}
				>
					<Trans i18nKey={`${content}`} />
				</p>
				<div className="flex flex-col sm:flex-row gap-4">
					<PrimaryBtn
						className="md:text-lg"
						onClick={() => window.requestADemo()}
					>
						<Trans i18nKey="common.cta.demo" />
					</PrimaryBtn>
				</div>
			</div>

			<Illustration
				className={mergeClasses({
					classes: [
						"w-[90%] mx-auto md:m-0 md:w-full h-auto order-[-1] md:order-1",
						illustrationClass,
					],
				})}
			/>
		</Container>
	)
}
