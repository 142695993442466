import React from "react"
import { graphql } from "gatsby"
import { Container } from "components/library"
import { DoeIllustration } from "components/library/illustrations/lab/design-of-experiments";
import { MiniNavbar } from "components/common/mini-navbar";
import { HeroLayout } from "components/common/hero-layout";
import { CtaBlock } from "components/common/cta-block";
import { DataDrivenDecision } from "components/pages/products/doe/data-driven-decision";
import { Purpose } from "components/pages/products/doe/purpose";
import { SolvingProblems } from "components/pages/products/doe/solving-problems";
import { UsedAtPolymerize } from "components/pages/products/doe/used-at-polymerize";

const DataManagement = () => {
	return (
		<main>
			<div className="flex flex-col gap-8 sm:gap-12">
				<Container className="flex flex-col gap-20 sm:gap-32 pt-16 sm:pt-12">
					<MiniNavbar
						className="hidden sm:flex"
						title="common.words.design-of-experiments"
						links={[
							{ label: "common.words.purpose", path: "#purpose" },
							{ label: "common.words.solution", path: "#solution" },
							{ label: "common.words.use", path: "#use" },
						]}
					/>
				</Container>
				<HeroLayout
					title="products-doe-page.hero.heading"
					titleClass="bg-gradient-to-r from-theme-orange to-theme-blue bg-clip-text text-transparent"
					content="products-doe-page.hero.desc"
					illustration={DoeIllustration}
				/>
			</div>

			<Container className="flex flex-col gap-20 py-20 sm:py-32">
				<DataDrivenDecision />
				<Purpose />
				<SolvingProblems />
				<UsedAtPolymerize />
			</Container>

			<CtaBlock />
		</main>
	);
}

export default DataManagement

// FOR LANGUAGE TRANSLATION
export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`
